import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'

const ChangePassword = (props) => {

    useEffect(() => {
        document.title = "Ultimate Organic Ecommerce - Change Password";
    }, []);
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     setLoading(true);
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 500);
    // }, []);

    let navigate = useNavigate();
    const [errorList,setError]= useState([]);
    let [currentPassword, setCurrentPassword] = useState("");
    let [password, setPassword] = useState("");
    let [passwordConfirm, setPasswordConfirm] = useState("");
    const [confirmPassErr, setConfirmPassErr] = useState("");
    const confirmPass = () => {
      if(password===passwordConfirm){
          setConfirmPassErr('');
      }
      else{
          
          setConfirmPassErr('Password and confirm password does not match!');
      }
    }

    const handleSubmit = (event) => {
      var obj = {
        id: props.customer_id,
        current_password: currentPassword,
        password: password,
        password_confirmation: passwordConfirm
      };
      axios
        .post("customer/change-password", obj)
        .then(function (resp) {
          console.log(resp.data);
          var data = resp.data;
          if (data.success_message) {
            Swal.fire({
              customClass: {
                  icon: 'mt-4'
              },
              position: 'center',
              icon: 'success',
              title: data.success_message,
              showConfirmButton: true,
              // timer: 8600
          }).then((result) => {
              if (result.isConfirmed) {
                navigate("/customer/dashboard");
                window.location.reload(false);
              }
          });
          }
          else if(data.failed_message){
            Swal.fire({
              customClass: {
                  icon: 'mt-4'
              },
              position: 'center',
              icon: 'error',
              title: data.failed_message,
              showConfirmButton: true,
              // timer: 8600
          }).then((result) => {
              if (result.isConfirmed) {
                  // location.reload();
              }
          });

          }
        })
        .catch((err) => {
          setError(err.response.data.errors)
        });
      event.preventDefault();
    }

    return (
        <div>
            {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
            <div className="tab-content account dashboard-content pl-md-50 pt-md-55">
            <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
              <div className="card">
                <div className="card">
                  <div className="card-header">
                    <h5>Change Password</h5>
                  </div>
                  <div className="card-body">
                    <form onSubmit={(e) => {
                              handleSubmit(e);
                            }}>
                      <div className="form-group ">
                        <label className="required" htmlFor="current_password">
                          Current password:
                        </label>
                        <input
                          type="password"
                          className="form-control square"
                          name="current_password"
                          id="current_password"
                          placeholder="Current password"
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                        <span className='text-danger'>{errorList.current_password}</span>
                      </div>
                      <div className="form-group ">
                        <label className="required" htmlFor="password">
                          New password:
                        </label>
                        <input type="password" className="form-control square" name="password" id="password" placeholder="New password" minLength={8}  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  onBlur={() => confirmPass()}/>
                        <span className='text-danger'>{errorList.password}</span>
                      </div>
                      <div className="form-group ">
                        <label className="required" htmlFor="password_confirmation">
                          Password confirmation:
                        </label>
                        <input
                          type="password"
                          className="form-control square"
                          name="password_confirmation"
                          id="password_confirmation"
                          placeholder="Password confirmation"
                          value={passwordConfirm}
                          minLength={8}
                          onChange={(e) => {setPasswordConfirm(e.target.value);}}
                          onKeyUp={() => confirmPass()}
                        />
                        {errorList.password_confirmation ? (
                        <span className='text-danger'>{errorList.password_confirmation}</span>
                        ) : (
                          <span className='text-danger'>{confirmPassErr}</span>
                        )}
                      </div>
                      <div className="col-md-12">
                        <button type="submit" className="btn btn-fill-out submit">
                          Change password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
    );
}

export default ChangePassword;