import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import axios from "axios";
import Home from "./pages/Home";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderData } from "./store/layoutData/HeaderData";
import { setFooterData } from "./store/layoutData/FooterData";
import { setFooterBanner } from "./store/layoutData/FooterBanner";
import { setCategoryData } from "./store/layoutData/CategoryData";
import { setSocialMediaData } from "./store/layoutData/SocialMediaData";
import { setFooterAdvertisementData } from "./store/layoutData/FooterAdvertisementData";
import { setHomeBannerData } from "./store/layoutData/HomeBannerData";
import Cart from "./pages/Cart";
import Wishlist from "./pages/Wishlist";
import Login from "./pages/Login";
import Register from "./pages/Register";
import AllProducts from "./pages/AllProducts";
import Blogs from "./pages/Blogs";
import BlogView from "./pages/BlogView";
import Contact from "./pages/Contact";
import AboutUs from './pages/AboutUs';
import CookiePolicy from './pages/CookiePolicy';
import Faq from './pages/Faq';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ReturnExchange from './pages/ReturnExchange';
import ShippingDelivery from './pages/ShippingDelivery';
import TermsCondition from './pages/TermsCondition';
import { setShippingChargesData } from "./store/checkoutData/ShippingChargesData";
import Checkout from "./pages/Checkout";
import CategoryWiseProducts from "./pages/CategoryWiseProducts";
import ProductDetails from "./pages/ProductDetails";
import PaymentSuccess from "./pages/Payment/PaymentSuccess";
import PaymentFailed from "./pages/Payment/PaymentFailed";
import UserData, { setUserData } from "./store/UserData";
import TrackOrder from "./pages/TrackOrder";
import MakePayment from "./pages/Payment/MakePayment";
import ForgetPassword from "./pages/ForgetPassword";
import OtpNewPassword from "./pages/OtpNewPassword";
import OtpVerify from "./pages/OtpVerify";
import { setDhakaShippingZoneData } from "./store/checkoutData/DhakaShippingZoneData";
import { setOutsideShippingZoneData } from "./store/checkoutData/OutsideShippingZoneData";
import CustomerDashboard from "./pages/CustomerDashboard";
import { setCustomerAddressData } from "./store/checkoutData/CustomerAddressData";
import NotFoundPage from "./pages/NotFoundPage";
import { setTopNewsData } from "./store/layoutData/TopNewsData";
import LoadCheckData, { setLoadCheckData } from "./store/LoadCheckData";


function App() {
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(true);
  const { UserData } = useSelector((state) => state.UserData);
  const { CategoryData } = useSelector((state) => state.CategoryData);
  const { LoadCheckData } = useSelector((state) => state.LoadCheckData);
  // useEffect(() => {
  //   axios.get("get-categories")
  //   .then(resp => {
  //     dispatch(setCategoryData(resp.data.categories));
  //     setLoading(false);
  //   });
  //   setLoading(true);

  //   axios.get("header-management")
  //     .then(resp => {
  //       dispatch(setHeaderData(resp.data));
  //       setLoading(false);
  //     });

  //   setLoading(true);
  //   axios.get("footer-management")
  //     .then(resp => {
  //       dispatch(setFooterData(resp.data.footerManagement));
  //       setLoading(false);
  //     });

  //   setLoading(true);
  //   axios.get("footer-banner")
  //   .then(resp => {
  //     dispatch(setFooterBanner(resp.data.banner[0]));
  //     setLoading(false);
  //   });

  //   setLoading(true);
  //   axios.get("social-media")
  //     .then(resp => {
  //       dispatch(setSocialMediaData(resp.data.socialMedia));
  //       setLoading(false);
  //     });
      
  //     setLoading(true);
  //     axios.get("footer-advertisement")
  //     .then(resp => {
  //       dispatch(setFooterAdvertisementData(resp.data.advertisement));
  //       setLoading(false);
  //     });

  //     setLoading(true);
  //     axios.get("homepage-banner")
  //     .then(resp => {
  //       dispatch(setHomeBannerData(resp.data.banner));
  //       setLoading(false);
  //     });

  //     setLoading(true);
  //     axios.get("news")
  //     .then(resp => {
  //       if(resp.data){
  //         dispatch(setTopNewsData(resp.data.news));
  //       }
  //       setLoading(false);
  //     });

  //     setLoading(true);
  //     axios.get("get-shipping-charges")
  //     .then(resp => {
  //       dispatch(setShippingChargesData(resp.data.shipping_charges));
  //       setLoading(false);
  //     });

  //     setLoading(true);
  //     axios.get("ec/area-by-district/dhaka")
  //     .then(resp => {
  //       dispatch(setDhakaShippingZoneData(resp.data.data.data));
  //     }).catch(err => {
  //       console.log(err);
  //     });
      
  //     axios.get("ec/get-cities")
  //     .then(resp => {
  //       dispatch(setOutsideShippingZoneData(resp.data.data));
  //     }).catch(err => {
  //       console.log(err);
  //     });

  //     if(localStorage.getItem("user")){
  //       dispatch(setUserData(JSON.parse(localStorage.getItem("user"))));
  //         axios
  //         .get("get-customer-addresses/" + JSON.parse(localStorage.getItem("user")).customer_id)
  //         .then(function (resp) {
  //           // console.log(resp.data.data);
  //           dispatch(setCustomerAddressData(resp.data.data));
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     }
      
  //     // console.log('use effect test rendering issue');
  //   }, []);

  // console.log(LoadCheckData);
    // useEffect(() => {
      if(LoadCheckData == 0){
        axios.get("settings")
        .then(resp => {
          // console.log(resp.data);
          dispatch(setCategoryData(resp.data.categories));
          dispatch(setHeaderData(resp.data.headerManagement));
          dispatch(setFooterData(resp.data.footerManagement));
          dispatch(setFooterBanner(resp.data.footerBanner[0]));
          dispatch(setSocialMediaData(resp.data.socialMedia));
          dispatch(setFooterAdvertisementData(resp.data.advertisement));
          dispatch(setHomeBannerData(resp.data.hompageBanner));
          dispatch(setShippingChargesData(resp.data.shipping_charges));
          // dispatch(setDhakaShippingZoneData(resp.data.dhaka_area.data));
          // dispatch(setOutsideShippingZoneData(resp.data.getCities));
        });
          axios.get("ec/area-by-district/dhaka")
          .then(resp => {
            dispatch(setDhakaShippingZoneData(resp.data.data.data));
          }).catch(err => {
            console.log(err);
          });
          
          axios.get("ec/get-cities")
          .then(resp => {
            dispatch(setOutsideShippingZoneData(resp.data.data));
          }).catch(err => {
            console.log(err);
          });
          if(localStorage.getItem("user")){
            dispatch(setUserData(JSON.parse(localStorage.getItem("user"))));
              axios
              .get("get-customer-addresses/" + JSON.parse(localStorage.getItem("user")).customer_id)
              .then(function (resp) {
                // console.log(resp.data.data);
                dispatch(setCustomerAddressData(resp.data.data));
              })
              .catch((err) => {
                console.log(err);
              });
          }

          dispatch(setLoadCheckData(1));
      }
        
        // console.log('use effect test rendering issue');
      // }, []);

  return (
    <Router>
      <Routes>

        <Route exact path="/" element={<Home />} />
        <Route exact path="/cart" element={<Cart />} />
        <Route exact path="/wish-list" element={<Wishlist />} />
        <Route exact path="/products" element={<AllProducts />} />

        <Route exact path="/blogs" element={<Blogs /> } />
        <Route exact path="/blog/:id/:slug" element={<BlogView />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/faq" element={<Faq />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/terms-conditions" element={<TermsCondition />} />
        <Route exact path="/cookie-policy" element={<CookiePolicy />} />
        <Route exact path="/returns-exchanges" element={<ReturnExchange />} />
        <Route exact path="/shipping-delivery" element={<ShippingDelivery />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/checkout/:pId/:qty" element={<Checkout />} />
        <Route exact path="/checkout/:pId/:qty/:attId" element={<Checkout />} />
        <Route exact path="/product-categories/:id/:cname" element={<CategoryWiseProducts />} />

        <Route exact path="/product_details/:id" element={<ProductDetails />} />
        <Route exact path="/product_details/:id/:name" element={<ProductDetails />} />

        {/* Payment Routes */}
        <Route exact path="/payment_success" element={<PaymentSuccess />} />
        <Route exact path="/payment_failed" element={<PaymentFailed />} />
        <Route exact path="/make-payment/:orderID" element={<MakePayment />} />

        <Route exact path="/track-order" element={<TrackOrder />} />

        {/* Login Register Routes */}
        <Route exact path="/login" element={<Login/>} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/forgot-password" element={<ForgetPassword />} />
        <Route exact path="/otp-verification/:id" element={<OtpVerify />} />
        <Route exact path="/verify-otp-set-new-password/:customer_id" element={<OtpNewPassword />} />

        {/* Customer Dashboard */}
        <Route exact path="/customer/:pgType" element={<CustomerDashboard />} />
        <Route exact path="/customer/:pgType/:add_id" element={<CustomerDashboard />} />
        <Route path="*" element={<NotFoundPage/>} />

      </Routes>
    </Router>
  );
}

export default memo(App);
