import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
import Parser from 'html-react-parser';
import Skeleton from "react-loading-skeleton";

const Blogs = () => {
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 500);
  // }, []);

  useEffect(() => {
    document.title = "Ultimate Organic Life | Blogs";
    window.scrollTo(0, 0);
  }, []);

  const [blogs, setBlogs] = useState();
  useEffect(() => {
    axios.get("blogs")
      .then(resp => {
        // console.log(resp.data);
        setBlogs(resp.data);
      });
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://ultimateorganiclife.com/"
    },{
      "@type": "ListItem",
      "position": 2,
      "name": "Blogs"
    }]
  };
  return (
    <div>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb">
            <Link to="/" itemProp="item" title="Home">
              Home
            </Link>
            <span></span> Blogs
            <span></span>
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-12 pt-1 pb-4">
            {blogs ?
              blogs.map((blog, index) => {
                return (
                  <div key={index} className="loop-grid loop-list pr-30 mb-50" style={{maxHeight: '5rem !important'}}>
                    <article className="wow fadeIn animated hover-up mb-30 animated animated" style={{visibility: "visible"}}>
                      <div className="post-thumb page_speed_954425642">
                        <img className="img-fluid" src={axios.defaults.baseURL.slice(0, -4) + "admin/images/blogs/" + blog.image} alt={blog.title} />
                      </div>
                      <div className="entry-content-2 pl-50">
                        <h3 className="post-title mb-20">
                          <Link to={"/blog/"+blog.id+"/"+blog.slug}>{blog.title}</Link>
                        </h3>
                        <p className="post-exerpt mb-40">{blog.sub_title}</p>
                        <div className="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
                          <div><span className="post-on mr-10">{blog.date}</span>
                            {/* <span className="hit-count has-dot mr-10">1,561 Views</span> */}
                          </div>
                          <Link to={"/blog/"+blog.id+"/"+blog.slug} className="text-brand font-heading font-weight-bold">Read more <i className="fi-rs-arrow-right"></i></Link>
                        </div>
                      </div>
                    </article>
                  </div>
                );
              })
              : (
                  <>
                  <div className="loop-grid loop-list pr-30 mb-50" style={{maxHeight: '5rem !important'}}>
                    <article className="wow fadeIn animated hover-up mb-30 animated animated" style={{visibility: "visible"}}>
                      <div className="post-thumb page_speed_954425642">
                        <Skeleton width={450} height={350} inline={true} className={'pt-0 mt-0'} />
                      </div>
                      <div className="entry-content-2 pl-50">
                        <h3 className="post-title mb-20">
                        <Skeleton width={300} inline={true} className='post-title' />
                        </h3>
                        <Skeleton width={200} inline={true} />
                        <div className="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
                          <div><span className="post-on mr-10"><Skeleton width={100} inline={true} /></span>
                          </div>
                          {/* <Link to={"#"} className="text-brand font-heading font-weight-bold">Read More<i className="fi-rs-arrow-right"></i></Link> */}
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="loop-grid loop-list pr-30 mb-50" style={{maxHeight: '5rem !important'}}>
                    <article className="wow fadeIn animated hover-up mb-30 animated animated" style={{visibility: "visible"}}>
                      <div className="post-thumb page_speed_954425642">
                        <Skeleton width={450} height={350} inline={true} className={'pt-0 mt-0'} />
                      </div>
                      <div className="entry-content-2 pl-50">
                        <h3 className="post-title mb-20">
                        <Skeleton width={300} inline={true} className='post-title' />
                        </h3>
                        <Skeleton width={200} inline={true} />
                        <div className="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
                          <div><span className="post-on mr-10"><Skeleton width={100} inline={true} /></span>
                          </div>
                          {/* <Link to={"#"} className="text-brand font-heading font-weight-bold">Read More<i className="fi-rs-arrow-right"></i></Link> */}
                        </div>
                      </div>
                    </article>
                  </div>
                  <div className="loop-grid loop-list pr-30 mb-50" style={{maxHeight: '5rem !important'}}>
                    <article className="wow fadeIn animated hover-up mb-30 animated animated" style={{visibility: "visible"}}>
                      <div className="post-thumb page_speed_954425642">
                        <Skeleton width={450} height={350} inline={true} className={'pt-0 mt-0'} />
                      </div>
                      <div className="entry-content-2 pl-50">
                        <h3 className="post-title mb-20">
                        <Skeleton width={300} inline={true} className='post-title' />
                        </h3>
                        <Skeleton width={200} inline={true} />
                        <div className="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
                          <div><span className="post-on mr-10"><Skeleton width={100} inline={true} /></span>
                          </div>
                          {/* <Link to={"#"} className="text-brand font-heading font-weight-bold">Read More<i className="fi-rs-arrow-right"></i></Link> */}
                        </div>
                      </div>
                    </article>
                  </div>
                  </>
              )}

          </div>
        </div>

      </Layout>
    </div>
  );
};

export default Blogs;
