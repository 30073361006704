import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../layouts/Layout";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import '../assets/Css/tracking.css'

const TrackOrder = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);
  useEffect(() => {
    document.title = "Ultimate Organic Life - Order Tracking";
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [trackingInfos, setTrackingInfos] = useState([]);
  const [ecourierTrackingInfos, setEcourierTrackingInfos] = useState();
  const [orderNumber, setOrderNumber] = useState();

  useEffect(() => {
    setLoading(true);
    setTrackingInfos([]);
    setEcourierTrackingInfos(null);

    axios.get("order-track?order_number=" + orderNumber)
      .then(resp => {
        setLoading(false);
        if (resp.data.success) {
          // console.log(resp.data);

          if (resp.data.data[0]) {
            setEcourierTrackingInfos(null);
            setTrackingInfos(resp.data.data);
          }
          if (resp.data.data.original.data.success) {
            setTrackingInfos([]);
            setEcourierTrackingInfos(resp.data.data.original.data.query_data.status);
          }
          else {
            setTrackingInfos([]);
            setEcourierTrackingInfos(null);
          }
          // if(resp.data.data.original.data.success===false){ 
          //   setTrackingInfos([]);
          //   setEcourierTrackingInfos(null);

          // }
        }
      });
  }, [orderNumber]);

  // console.log(trackingInfos);
  // const track = (orderNo) => {
  //   // console.log(orderNo + "track theke");
  //     axios.get("order-track?order_number=" + orderNo)
  //     .then(resp => {
  //       console.log(resp.data);
  //       setTrackingInfo(resp.data.data);
  //     });
  // }
  const ConvertToTwelveHour = (hour, min) => {
    var hours = hour; // gives the value in 24 hours format
    var AmOrPm = hours >= 12 ? 'pm' : 'am';
    hours = (hours % 12) || 12;
    var minutes = min;
    var finalTime = hours + ":" + minutes + " " + AmOrPm;
    return finalTime;
  }

  return (
    <div>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb">
            <Link to="/" itemProp="item" title="Home">
              Home
            </Link>
            <span></span> Track Order
            <span></span>
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 pt-1 pb-4 ">
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Type Your Order Number"
                aria-label="Type Your Order Number"
                aria-describedby="basic-addon2"
                value={orderNumber}
                onChange={(e) => { setOrderNumber(e.target.value); }}
              />
              <Button variant="outline-secondary" id="button-addon2"
              // onClick={track(orderNumber)}
              >
                Track Order
              </Button>
            </InputGroup>
          </div>
          <div className="col-md-8 col-lg-8">
            {!trackingInfos[0] && !ecourierTrackingInfos && orderNumber && (
              <div className="text-warning text-center">
                No Order Found !
              </div>
            )}
              <div className="track">
                <div className="track-content">
                  <div className="track-info">
                    <ul>
                      <li className={trackingInfos[0] || ecourierTrackingInfos && orderNumber ? "active" : "d-none"}>
                        {!trackingInfos && ecourierTrackingInfos && (
                            ecourierTrackingInfos[0].time.slice(2, 10)
                        )
                        }
                        <ul>
                          {ecourierTrackingInfos ? (
                            ecourierTrackingInfos.map((trackingInfo, index) => {
                              // console.log(index);
                              return (
                                <li key={index}>
                                  <h4>{ConvertToTwelveHour(trackingInfo.time.slice(11, 13), trackingInfo.time.slice(14, 16))}</h4>
                                  <div> <b>{trackingInfo.status}</b>
                                    Comment: <strong> {trackingInfo.comment}</strong><br/>
                                    Date: <strong>{trackingInfo.time.slice(2, 10)}</strong>
                                  </div>
                                </li>
                              );
                            })
                          ) : (
                            trackingInfos.map((trackingInfo, index) => {
                              if(trackingInfo.properties){
                              return (
                                <li key={index}>
                                  <h5>{ConvertToTwelveHour(trackingInfo.created_at.slice(11, 13), trackingInfo.created_at.slice(14, 16))}</h5>
                                  <div> <b>{trackingInfo.properties.attributes && (trackingInfo.properties.attributes.status.charAt(0).toUpperCase() + trackingInfo.properties.attributes.status.slice(1))}</b>
                                    Comment: <strong> {trackingInfo.description.charAt(0).toUpperCase() + trackingInfo.description.slice(1)}</strong> <br/>
                                    Date: <strong>{trackingInfo.created_at.slice(0, 10)}</strong>
                                  </div>
                                </li>
                              );
                            }
                            })
                          )
                          }
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
          </div>
          {/* <div className="col-md-8 col-lg-8">
            <div className="track">
              <div className="track-content">
                <div className="track-info">
                  <ul>
                    <li className="active">
                      Tuesday, February 28 2023
                      <ul>
                        <li>
                          <h4>4:20 pm</h4>
                          <div> <b>Delivered</b>
                            Name: <strong> MD. BAPPI KHAN(D0717)</strong><br />
                            Location: <strong>  Badda Branch - Dhaka - Badda</strong><br />
                            Group: <strong> Agent</strong>
                          </div>
                        </li>

                        <li>
                          <h4>10:50 am</h4>
                          <div> <b>On the way to Delivery</b>
                            Name: <strong> MD. BAPPI KHAN(D0717)</strong><br />
                            Location: <strong>  Badda Branch - Dhaka - Badda</strong><br />
                            Group: <strong> Agent</strong>
                          </div>
                        </li>

                        <li>
                          <h4>10:02 am</h4>
                          <div> <b>Delivery Agent Assigned</b>
                            Name: <strong> Badda Branch(Badda)</strong><br />
                            Location: <strong>  Badda Branch - Dhaka</strong><br />
                            Group: <strong> Branch</strong>
                          </div>
                        </li>


                        <li>
                          <h4>4:07 am</h4>
                          <div> <b>Parcel process by Fulfillment</b>
                            Name: <strong> AR IF(E0579)</strong><br />
                            Location: <strong>  Fulfillment</strong><br />
                            Group: <strong> eCourier Fullfillemnt</strong>
                          </div>
                        </li>

                        <li>
                          <h4>2:18 am</h4>
                          <div> <b>Initiated</b>
                            Name: <strong> Md. Mizanur Rahman Rifat (Corporate)(E0315)</strong><br />
                            Location: <strong>  Badda Branch - Dhaka</strong><br />
                            Group: <strong> eCourier Fullfillemnt</strong>
                          </div>
                        </li>

                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
        </div>

      </Layout>
    </div>
  );
};

export default TrackOrder;
