import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";

import axios from "axios";
import Swal from "sweetalert2";
import Layout from "../layouts/Layout";



const Login = () => {
  useEffect(() => {
    document.title = "Ultimate Organic Life | Login";
    window.scrollTo(0, 0);
  }, []);
  const search = useLocation().search;
  const queryParam = new URLSearchParams(search);
  
  let [username, setUserName] = useState("");
  let [password, setPassword] = useState("");

  let navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // console.log("Full render")
    setLoading(true);
    if(queryParam.get('q') != null){
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: queryParam.get('q'),
        // showConfirmButton: false,
        // timer: 2000
      })
    }
    
    setTimeout(() => {
        setLoading(false);
    }, 100);
  }, []);
  const loginSubmit = (e) => {
    var obj = { user_input: username, password: password };
    setLoading(true);
    axios
      .post("customer/login", obj)
      .then(function (resp) {
        // console.log(resp.data);
          setLoading(false);
        var data = resp.data;

        if (data.success === false) {
          setError(resp.data.message);
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: resp.data.message,
            showConfirmButton: false,
            timer: 2000
          })
        }
        // console.log(localStorage.getItem("user"));
        if (data.status) {
          var user = { token: data.token, customer_id: data.data.id, customer_name: data.data.customer_name, user: data.data };
          localStorage.setItem("user", JSON.stringify(user));
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Successfully logged in',
            showConfirmButton: false,
            timer: 1500
          })
          navigate("/");
          window.location.reload(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    e.preventDefault();
  };
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://ultimateorganiclife.com/"
    },{
      "@type": "ListItem",
      "position": 2,
      "name": "Login",
      "item": "https://ultimateorganiclife.com/login"
    }]
  };
  return (
    <div>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
        <Layout>
        {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
        <main className="main" id="main-section">
          <div className="page-header breadcrumb-wrap">
              <div className="breadcrumb" itemScope itemType="">
                <Link to="/" itemProp="item" title="Home">
                  Home
                  <meta itemProp="name" content="Home" />
                  <meta itemProp="position" content="1" />
                </Link>
                <span></span> Login
                <meta itemProp="name" content="Login" />
                <meta itemProp="position" content="2" />
              </div>
          </div>
          <div className="page-content pt-150 pb-150">
              <div className="row">
                <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                  <div className="row">
                    <div className="col-lg-6 pr-30 d-none d-lg-block">
                      <img className="border-radius-15" src={"/assets/images/logo/Original_logo.png"} alt="" />
                    </div>
                    <div className="col-lg-6 col-md-8">
                      <div className="login_wrap widget-taber-content background-white">
                        <div className="padding_eight_all bg-white">
                          <div className="heading_s1">
                            <h1 className="mb-5">Login</h1>
                            <p className="mb-30">
                              Don&#039;t have an account? <Link to="/register">Create one</Link>
                            </p>
                          </div>
                          <span style={{ color: "red" }}>{error}</span>
                          <form
                            onSubmit={(e) => {
                              loginSubmit(e);
                            }}
                          >
                            <div className="form-group">
                              {/*   <input type="text" required name="text" placeholder="Username or Email*" /> */}
                              <input
                                type="text"
                                name="username"
                                id="login_username"
                                placeholder="Phone Or Email*"
                                required
                                value={username}
                                onChange={(e) => setUserName(e.target.value)}
                              />
                            </div>
                            {/*
                             */}{" "}
                            <div className="form-group">
                              <input
                                type="password"
                                name="password"
                                id="login_password"
                                placeholder="Password*"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                autoComplete={'false'}
                              />
                            </div>
                            <div className="login_footer form-group mb-50">
                              <Link className="text-muted" to="/forgot-password">
                                Forgot password?
                              </Link>
                            </div>
                            <div className="form-group">
                              <button type="submit" className="btn btn-heading btn-block hover-up">
                                Login
                              </button>
                            </div>
                            <div className="text-left"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </main>
        </Layout>
    </div>
  );
};

export default Login;
