import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../layouts/Layout";

const Register = () => {
  useEffect(() => {
    document.title = "Ultimate Organic Life | Registration";
  }, []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  const [errorList, setError] = useState([]);
  let navigate = useNavigate();
  let [username, setUsername] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [passwordConfirm, setPasswordConfirm] = useState("");
  let [contact, setContact] = useState("");
  let [dob, setDob] = useState("");
  let [gender, setGender] = useState("");

  // const [type, setType] = useState("text");

  const [confirmPassErr, setConfirmPassErr] = useState("");
  const confirmPass = () => {
    if (password === passwordConfirm) {
      setConfirmPassErr('');
    }
    else {
      setConfirmPassErr('Password and confirm password does not match!');
    }
  }

  const handleSubmit = (event) => {

    var obj = {
      customer_name: username,
      customer_password: password,
      customer_password_confirmation: passwordConfirm,
      customer_email: email,
      customer_contact: contact,
      customer_dob: dob,
      customer_gender: gender,
    };
    // console.log(obj);
    axios
      .post("register-customer", obj)
      .then(function (resp) {
        // console.log('then er vitor');
        // console.log(resp.data);
        if (resp.data.success_message) {
          navigate("/otp-verification/"+resp.data.customer.id);
          window.location.reload(false);
        }
        else{
          setError(resp.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.message);
        // setError(err.response.message);
      });
    event.preventDefault();
  };
  return (
    <div>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <main className="main" id="main-section">
          <div className="page-header breadcrumb-wrap">
            <div className="breadcrumb">
              <Link to="/" itemProp="item" title="Home">
                Home
                <meta itemProp="name" content="Home" />
                <meta itemProp="position" content="1" />
              </Link>
              <span></span> Register
              <meta itemProp="name" content="Register" />
              <meta itemProp="position" content="2" />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="login_wrap widget-taber-content background-white">
                <div className="padding_eight_all bg-white">
                  <div className="padding_eight_all bg-white">
                    <h3 className="mt-20">Register</h3>
                    <p className="mb-20">
                      Have an account already?{" "}
                      <Link to="/login" className="d-inline-block">
                        Login
                      </Link>
                    </p>
                    <p>Please fill in the information below</p>
                  </div>
                  <form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <div className="form__content">
                      <div className="form-group">
                        <input
                          className="form-control"
                          name="name"
                          id="txt-name"
                          type="text"
                          placeholder="Your name *"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                        <span className='text-danger ps-1'>{errorList.customer_name}</span>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="contact"
                          id="contact"
                          placeholder="Contact Number *"
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                        />
                        <span className='text-danger ps-1'>{errorList.customer_contact}</span>
                      </div>

                      <div className="form-group">
                        <input
                          className="form-control"
                          type="password"
                          name="password"
                          id="txt-password"
                          placeholder="Your password *"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onBlur={() => confirmPass()}
                        />
                        <span className='text-danger ps-1'>{errorList.customer_password}</span>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="password"
                          name="password_confirmation"
                          id="txt-password-confirmation"
                          placeholder="Password confirmation *"
                          value={passwordConfirm}
                          onChange={(e) => setPasswordConfirm(e.target.value)}
                          onKeyUp={() => confirmPass()}
                        />
                        <span className='text-danger ps-1'>{confirmPassErr}</span>
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control"
                          name="email"
                          id="txt-email"
                          type="email"
                          placeholder="Your email address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <span className='text-danger ps-1'>{errorList.customer_email}</span>
                      </div>
                      <div className="form-group">
                        <label htmlFor="dob">Date Of Birth</label>
                        <input
                          className="form-control"
                          type={'date'}
                          // onFocus={() => setType("date")}
                          name="dob"
                          id="dob"
                          placeholder="Date of Birth *"
                          value={dob}
                          onChange={(e) => setDob(e.target.value)}
                        />
                        <span className='text-danger ps-1'>{errorList.customer_dob}</span>
                      </div>
                      <div style={{ paddingLeft: "0.5rem" }}>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input ui_bg_color" type="radio" id="male" name="gender" value={"male"} onChange={(e) => setGender(e.target.value)} />
                          <label className="form-check-label" htmlFor="male">Male</label>&nbsp;
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input ui_bg_color" type="radio" id="female" name="gender" value={"female"} onChange={(e) => setGender(e.target.value)} />
                          <label className="form-check-label" htmlFor="female">Female</label>&nbsp;
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input ui_bg_color" type="radio" id="other" name="gender" value={"other"} onChange={(e) => setGender(e.target.value)} />
                          <label className="form-check-label" htmlFor="other">Other</label>
                        </div>
                        <br/>
                        <span className='text-danger'>{errorList.customer_gender}</span>
                      </div>
                      <div className="login_footer form-group">
                        <div className="chek-form">
                        </div>
                      </div>
                      <div className="form-group">
                        <button type="submit" className="btn btn-fill-out btn-block hover-up">
                          Register
                        </button>
                      </div>
                      <br />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>

    </div>
  );
};

export default Register;
