import React from "react";
import { Link } from "react-router-dom";
const BestsellBanner = () => {
  
    return (
      /* <div className="col-xl-12 d-none d-xl-flex wow animate__ animate__fadeIn" style={{ visibility: "visible", animationName: "fadeIn" }}> */
      <div className="d-xl-flex wow animate__ animate__fadeIn">
        <div className="banner-img style-2" style={{ backgroundImage: 'URL("assets/images/bring3.jpeg")', maxHeight: '490px' }}>
          <div className="banner-text">
            <h2 className="mb-100">Bring nature into your home</h2>
            <Link to="/products" className="btn btn-xs">
              Shop now
              <i className="fi-rs-arrow-small-right"></i>
            </Link>
          </div>
        </div>
      </div>
    )
}

export default BestsellBanner;
