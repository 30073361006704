import { createSlice } from "@reduxjs/toolkit";

export const SocialMediaDataSlice = createSlice({
  name: "SocialMediaData",
  initialState: {
    SocialMediaData: [],
  },
  reducers: {
    setSocialMediaData: (state, action) => {
      state.SocialMediaData = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setSocialMediaData } = SocialMediaDataSlice.actions;

export default SocialMediaDataSlice.reducer;

