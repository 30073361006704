import { configureStore } from "@reduxjs/toolkit";
import wishlistCount from "./Counter";
import HeaderData from "./layoutData/HeaderData";
import FooterData from "./layoutData/FooterData";
import FooterBanner from "./layoutData/FooterBanner";
import CategoryData from "./layoutData/CategoryData";
import FooterAdvertisementData from "./layoutData/FooterAdvertisementData";
import SocialMediaData from "./layoutData/SocialMediaData";
import HomeBannerData from "./layoutData/HomeBannerData";
import ShippingChargesData from "./checkoutData/ShippingChargesData";
import DhakaShippingZoneData from "./checkoutData/DhakaShippingZoneData";
import OutsideShippingZoneData from "./checkoutData/OutsideShippingZoneData";
import UserData from "./UserData";
import CustomerAddressData from "./checkoutData/CustomerAddressData";
import TopNewsData from "./layoutData/TopNewsData";
import LoadCheckData from "./LoadCheckData";

export default configureStore({
  reducer: {
    wishlistCount: wishlistCount,
    HeaderData: HeaderData,
    FooterData: FooterData,
    FooterBanner: FooterBanner,
    CategoryData: CategoryData,
    FooterAdvertisementData: FooterAdvertisementData,
    SocialMediaData: SocialMediaData,
    HomeBannerData: HomeBannerData,
    ShippingChargesData: ShippingChargesData,
    DhakaShippingZoneData: DhakaShippingZoneData,
    OutsideShippingZoneData: OutsideShippingZoneData,
    UserData: UserData,
    CustomerAddressData: CustomerAddressData,
    TopNewsData: TopNewsData,
    LoadCheckData: LoadCheckData,
  }
});
