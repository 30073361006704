import { createSlice } from "@reduxjs/toolkit";

export const HeaderDataSlice = createSlice({
  name: "HeaderData",
  initialState: {
    HeaderData: [],
  },
  reducers: {
    setHeaderData: (state, action) => {
      state.HeaderData = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setHeaderData } = HeaderDataSlice.actions;

export default HeaderDataSlice.reducer;

