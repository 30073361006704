import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Layout from "../layouts/Layout";
import Parser from 'html-react-parser';
import Skeleton from "react-loading-skeleton";
import '../assets/Css/blog.css';
import SEO from "../components/Seo";

const BlogView = () => {
  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 500);
  // }, []);
  useEffect(() => {
    document.title = "Ultimate Organic Life | Blog";
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const { id } = useParams();
  const [blog, setBlog] = useState();
  useEffect(() => {
    axios.get("blog/" + id)
      .then(resp => {
        // console.log(resp.data.data);
        setBlog(resp.data.data);
      });
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://ultimateorganiclife.com/"
    },{
      "@type": "ListItem",
      "position": 2,
      "name": "Blogs",
      "item": "https://ultimateorganiclife.com/blogs"
    },{
      "@type": "ListItem",
      "position": 3,
      "name": blog && (blog.title)
    }]
  };

  return (
    <div>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      {blog && (
        <SEO title={blog.title ? 'Ultimate Organic Life | '+blog.title : 'Ultimate Organic Life | Blog View'} meta_title={blog.meta_title ? blog.meta_title : ""} 
        meta_description={blog.meta_description ? blog.meta_description : ""} keywords={blog.meta_keywords ? blog.meta_keywords : ""}
        name={'Ultimate Organic Life'} type={'ecommerce.blog'} meta_image={blog.image ? blog.image.large : "Blog Image"} />
      )}
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb">
            <Link to="/" itemProp="item" title="Home">
              Home
            </Link>
            <span></span>
            <Link to="/blogs" itemProp="item" title="Blogs">
              Blogs
              <meta itemProp="name" content="Blogs" />
              <meta itemProp="position" content="2" />
            </Link>
            <span></span> {blog && blog.title}
            <span></span>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-9 pt-1 pb-4">
            {/* {blog && ( */}
              <div className="single-page">
                <div className="single-header style-2">
                  <div className="row">
                    <div className="col-lg-12 m-auto p-0">
                      {/* <h6 className="mb-10"><a href="#">{blog ? blog.category.name : <Skeleton inline={true} width={'12%'} />}</a></h6> */}
                      <h2 className="mb-10">{blog ? blog.title : <Skeleton inline={true} width={'40%'} />}</h2>
                      <div className="single-header-meta">
                        <div className="entry-meta meta-1 font-xs mt-15 mb-15">
                          {/* <span className="author-avatar">
                            <img src="data:image/png;base64,5CYII=" alt="System Admin" className="img-circle" />
                          </span> */}
                          <span className="post-by">{blog ? 'By '+ blog.author : <Skeleton inline={true} width={130} />}</span>
                          <span className="post-on has-dot">{blog ? blog.created_at.slice(0,10) : <Skeleton inline={true} width={80} />}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <center>
                <figure className="single-thumbnail">
                  {blog && <img src={axios.defaults.baseURL.slice(0, -4) + "admin/images/blogs/" + blog.image} alt={blog.title} onLoad={()=>{setImageLoaded(true);}} />}  {!imageLoaded && <Skeleton className="blog_image_skelton" inline={true} height={640} />}
                </figure>
                </center>
                <div className="single-content">
                  <div className="row">
                    <div className="col-lg-12 m-auto p-0 blog_description">
                    {blog ? Parser(blog.description) : <Skeleton count={13} />}
                    </div>
                  </div>
                </div>
              </div>
              {/* )} */}

          </div>
        </div>

      </Layout>
    </div>
  );
};

export default BlogView;
