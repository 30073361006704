import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'

const Profile = (props) => {

    const [loading, setLoading] = useState(false);

        var user = JSON.parse(localStorage.getItem("user"));
        // console.log(user);
        var customer_id = null;
        if (user) {
            customer_id = user.customer_id;
        }
    let navigate = useNavigate();
    const [errorList,setError]= useState([]); 
    let [username, setUsername] = useState('');
    let [email, setEmail] = useState('');
    let [contact, setContact] = useState('');
    let [dob, setDob] = useState('');
    let [gender, setGender] = useState('');
    
    useEffect(() => {
        setUsername(props.apiProfileData.customer_name);
        setEmail(props.apiProfileData.customer_email);
        setContact(props.apiProfileData.customer_contact);
        setDob(props.apiProfileData.customer_dob);
        setGender(props.apiProfileData.customer_gender);
      }, []);

    // console.log(props.userData);
    const handleSubmit = (event) => {
        var obj = {
          id: props.userData.id,
          customer_name: username,
          customer_email: email,
          customer_contact: contact,
          customer_dob: dob,
          customer_gender: gender,
        };
// console.log(obj);
        axios
          .post("get-edit-customer/"+props.userData.id, obj)
          .then(function (resp) {
            var data = resp.data;
            // console.log(data);
            if (data.success_message) {
                Swal.fire({
                    customClass: {
                        icon: 'mt-4'
                    },
                    position: 'center',
                    icon: 'success',
                    title: data.success_message,
                    showConfirmButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/");
                        window.location.reload(false);
                    }
                });
                window.location.reload(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setError(err.response.data.errors)
          });
        event.preventDefault();
      };
    return (
        <div>
            {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
            <div className="tab-content account dashboard-content pl-md-50">
                <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                    <div className="card">
                    <div className="card">
                        <div className="card-header">
                        <h5>Account Details</h5>
                        </div>
                        <div className="card-body">
                        <form method="POST" onSubmit={(e) => { handleSubmit(e); }}>
                            <div className="row">
                            <div className="form-group col-md-12 ">
                                <label className="required" htmlFor="name">
                                Full Name:
                                </label>
                                <input required className="form-control square" name="name" type="text" id="name" value={username} onChange={(e) => setUsername(e.target.value)} />
                                <span className='text-danger'>{errorList.customer_name}</span>
                            </div>
                            <div className="form-group col-md-12 ">
                                <label className="required" htmlFor="phone">Phone:</label>
                                <input
                                type="text"
                                className="form-control square"
                                name="phone"
                                id="phone"
                                placeholder="Phone"
                                value={contact}
                                onChange={(e) => setContact(e.target.value)}
                                />
                                <span className='text-danger'>{errorList.customer_contact}</span>
                            </div>
                            <div className="form-group col-md-12">
                                <label className="required" htmlFor="email">Email:</label>
                                <input
                                id="email"
                                type="text"
                                className="form-control"
                                // disabled="disabled"
                                value={email}
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                                />
                                <span className='text-danger'>{errorList.customer_email}</span>
                            </div>
                            <div className="form-group col-md-12 ">
                                <label className="required" htmlFor="date_of_birth">Date of birth:</label>
                                <input
                                id="date_of_birth"
                                type="date"
                                className="form-control square"
                                name="dob"
                                placeholder="Y-m-d"
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                                />
                                <span className='text-danger'>{errorList.customer_dob}</span>
                            </div>

                            <div className="col-md-12 p-3">
                                <label className="required" htmlFor="phone">Gender:</label>&nbsp;&nbsp;&nbsp;
                                <div className="form-check form-check-inline">
                                <input className="form-check-input ui_bg_color" type="radio" id="male" name="gender" value={"male"} onChange={(e) => setGender(e.target.value)} checked={gender==='male'} />
                                <label className="form-check-label" htmlFor="male">Male</label>&nbsp;
                                </div>
                                <div className="form-check form-check-inline">
                                <input className="form-check-input ui_bg_color" type="radio" id="female" name="gender" value={"female"} onChange={(e) => setGender(e.target.value)} checked={gender==='female'} />
                                <label className="form-check-label" htmlFor="female">Female</label>&nbsp;
                                </div>
                                <div className="form-check form-check-inline">
                                <input className="form-check-input ui_bg_color" type="radio" id="other" name="gender" value={"other"} onChange={(e) => setGender(e.target.value)} checked={gender==='other'} />
                                <label className="form-check-label" htmlFor="other">Other</label>
                                </div>
                                <span className='text-danger'>{errorList.customer_gender}</span>
                            </div>
                            
                            <div className="col-md-12">
                                <button type="submit" className="btn btn-fill-out submit">
                                Update
                                </button>
                            </div>
                            </div>
                        </form>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;