import React from "react";
import { Link } from "react-router-dom";
import { useCart } from "react-use-cart";
import Swal from "sweetalert2";
import Layout from "../layouts/Layout";
import { useState } from "react";
import { useEffect } from "react";
import '../assets/Css/cart.css';
import emptyCart from '../assets/images/empty-cart.svg';

const Cart = () => {
  // { items, IncreaseQuantity, DecreaseQuantity, DeleteCart }
  //  console.log(items)
  useEffect(() => {
    document.title = "Ultimate Organic Life | Cart";
    window.scrollTo(0, 0);
  }, []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);
  let ListCart = [];
  let TotalCart = 0;
  const {
    isEmpty,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
  } = useCart();

  let totalPrice = 0;

  items.forEach(function (item) {
    totalPrice += item.quantity * item.price;
  });
  // console.log(items);
  return (
    <div>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div itemScope="itemscope" itemType="" className="breadcrumb">
              <Link to="/" itemProp="item" title="Home">
                Home
                <meta itemProp="name" content="Home" /> <meta itemProp="position" content="1" />
              </Link>{" "}
              <span></span>
              Shopping Cart
              <meta itemProp="name" content="Shopping Cart" /> <meta itemProp="position" content="2" />
            </div>
          </div>
        </div>
        <div className="mb-80 mt-10 section--shopping-cart">
          {totalUniqueItems>0 ? (
            <>
          <div className="row">
          <div className="col-lg-6 mb-40">
            <h1 className="heading-2 mb-10">Your Cart</h1>{" "}
            <div className="d-flex justify-content-between">
              <h6 className="text-body">
                There are <span className="text-brand">{totalUniqueItems}</span> products in your cart
              </h6>
            </div>
          </div>
        </div>{" "}
        {/* <form method="post" action="#"> */}
        <div className="row">
          <div className="col-lg-8">
            <div className="table-responsive shopping-summery">
              <table className="table table-wishlist table--cart">
                <thead>
                  <tr className="main-heading">
                    <th scope="col" colSpan="2" className="start pl-30">
                      Product
                    </th>
                    <th scope="col">Price</th>
                    <th scope="col" className="pl-30">Variation</th>
                    <th scope="col" className="pl-30">Quantity</th>
                    <th scope="col" className="pl-30">Subtotal</th>
                    <th scope="col" className="end pl-30">Remove&nbsp;&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, key) => {
                    return (
                      <tr key={key} className="pt-30">
                        <td className="image product-thumbnail" data-title="Product">
                          <img src={item.image} alt="Foster Farms Takeout Crispy classNameic" />
                        </td>
                        <td className="product-des product-name" style={{ paddingLeft: '1rem' }} data-title="Name">
                          <h6 className="mb-5">
                            <Link to={"/product_details/" + item.product_id} className="product-name mb-10 text-heading">
                              {item.name}
                            </Link>
                          </h6>
                          {/* <p className="mb-0"> <small>(Weight: 4KG)</small></p> */}
                        </td>
                        <td data-title="Price" className="price">
                          <h5 className="text-body"> ৳{item.price} </h5>{" "}
                          {/* <small>
                                <del>$90.00</del>
                              </small> */}
                        </td>
                        <td data-title="Variation" className="pl-30">
                          <h5 className="text-body"> {item.variation ? item.variation.value : 'N/A'} </h5>{" "}
                          {/* <small>
                                <del>$90.00</del>
                              </small> */}
                        </td>

                        <td data-title="Quantity" className="" style={{ whiteSpace: 'nowrap' }}>
                          <div
                            className="quantity_button_group">
                            <button
                              className="quantity_button"
                              disabled={item.quantity <= 1}
                              onClick={() => updateItemQuantity(item.id, item.quantity - 1)}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="2px" viewBox="0 0 12 1.5">
                                <rect data-name="Rectangle 970" width="12px" height="2px" fill="currentColor"></rect>
                              </svg>
                            </button>
                            <input type='number' className="qty-input quantity_value" value={item.quantity} onChange={(e) => updateItemQuantity(item.id, e.target.value)} readOnly/>
                            <button
                              className="quantity_button" onClick={() => updateItemQuantity(item.id, item.quantity + 1)} 
                              disabled={item.variation ? item.variation.stock <= item.quantity : item.stock <= item.quantity} >
                              <svg data-name="plus (2)" xmlns="http://www.w3.org/2000/svg" width="12px" height="12px"
                                viewBox="0 0 12 12">
                                <g data-name="Group 5367">
                                  <path data-name="Path 17138"
                                    d="M6.749,5.251V0h-1.5V5.251H0v1.5H5.251V12h1.5V6.749H12v-1.5Z"
                                    fill="currentColor"></path>
                                </g>
                              </svg>
                            </button>
                          </div>
                          {" "}
                        </td>

                        <td data-title="Subtotal" className="price">
                          <h5 className="text-body text-center"> ৳{(item.price * item.quantity)} </h5>{" "}
                          {/* <small>
                                <del>$90.00</del>
                              </small> */}
                        </td>
                        <td data-title="Remove" className="action text-center" onClick={() => removeItem(item.id)}>
                          <Link to="#" data-url="" className="text-body remove-cart-button" onClick={() =>
                            Swal.fire({
                              position: 'top-end',
                              icon: 'warning',
                              title: 'Product removed form the cart',
                              showConfirmButton: false,
                              timer: 800
                            })
                          }>
                            <i className="fi-rs-trash"></i>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>{" "}
            <div className="divider-2 mb-30"></div>{" "}
            <div className="cart-action d-flex justify-content-between">
              <Link to={"/"} className="btn ">
                <i className="fi-rs-arrow-left mr-10"></i>Continue Shopping
              </Link>
            </div>{" "}
          </div>
          {totalUniqueItems !== 0 &&
            <div className="col-lg-4">
              <div className="border">
                <div className="table-responsive">
                  <table className="table no-border">
                    <tbody>
                      <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Tax</h6>
                        </td>{" "}
                        <td className="cart_total_amount">
                          <h4 className="text-brand text-end">--</h4>
                        </td>
                      </tr>{" "}
                      <tr>
                        <td className="cart_total_label">
                          <h6 className="text-muted">Total</h6> <small>(Shipping fees not included)</small>
                        </td>{" "}
                        <td className="cart_total_amount">
                          <strong>
                            <h4 className="text-brand text-end">{totalPrice} BDT</h4>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>{" "}
                {/* <button type="submit" name="checkout" className="btn mb-20">
                    Proceed To Checkout <i className="fi-rs-sign-out ml-15"></i>
                  </button> */}
                <center>
                  <Link to="/checkout" name="checkout" className="btn mb-20">
                    Proceed To Checkout <i className="fi-rs-sign-out ml-15"></i>
                  </Link>
                </center>
              </div>
            </div>
          }
        </div>
        {/* </form> */}
        </>
          ) : (
            <center>
              <img src={emptyCart} alt="f" width='400' />
              <h2>Your Cart is Empty</h2>
              <p>Looks like you haven't added anything to your cart yet</p>
            </center>
          )}
        </div>

        {/* <div className="row">
          <div className="col-lg-7">
            <div className="">
              <h4 className="mb-10">Apply Coupon</h4>
              <p className="mb-30"><span className="font-lg text-muted">Using A Promo Code?</span></p>
              <div className="d-flex justify-content-between form-coupon-wrapper">
                <input type="text" name="coupon_code" value="" placeholder="Enter Your Coupon" className="font-medium mr-15 coupon coupon-code" />
                <button type="button" data-url="" className="btn btn-apply-coupon-code"><i className="fi-rs-label mr-10"></i>Apply</button>
              </div>
            </div>
          </div>
        </div> */}

        {/* related product thakbe */}
        {/* <div className="row mt-60" id="related-products">
          <div className="col-12">
            <h3 className="section-title style-1 mb-30">You may also like</h3>
          </div>
        </div> */}
        <div>
          {" "}
        </div>
      </Layout>
    </div>
  );
}

export default Cart;
