import React, { useState, useEffect } from 'react';
import ProductBoxes from '../components/ProductBoxes';
import { Link } from 'react-router-dom';
import Layout from '../layouts/Layout';

const AllProducts = () => {
  //All products page :(
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Ultimate Organic Life | All Products";
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://ultimateorganiclife.com/"
    },{
      "@type": "ListItem",
      "position": 2,
      "name": "Products"
    }]
  };

  return (
    <div>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
              <Link to="/" itemProp="item" title="Home">
                Home
                <meta itemProp="name" content="Home" />
                <meta itemProp="position" content="1" />
              </Link>
              <span></span> Products
              <meta itemProp="name" content="Products" />
                <meta itemProp="position" content="2" />
            <span></span>
          </div>
        </div>
        <br />
        <ProductBoxes productType="get-products"/>
        <br />
      </Layout>

    </div>
  );
}

export default AllProducts;