import { createSlice } from "@reduxjs/toolkit";

export const wishlistCountSlice = createSlice({
  name: "wishlistCount",
  initialState: {
    wishlistCount: 0,
  },
  reducers: {
    increment: (state) => {
      state.wishlistCount += 1;
    },
    decrement: (state) => {
      state.wishlistCount -= 1;
    },
    incrementByAmount: (state, action) => {
      state.wishlistCount = action.payload;
    }
  }
});

export const { increment, decrement, incrementByAmount } = wishlistCountSlice.actions;

export default wishlistCountSlice.reducer;

