import React from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useCart } from "react-use-cart";
import '../assets/Css/dailyBestSales.css';
import '../assets/Css/product_skelton.css';
import Swal from 'sweetalert2';
import { useDispatch } from "react-redux";
import { increment } from "../store/Counter";
import Skeleton from "react-loading-skeleton";

const ProductBoxes = (props) => {
  
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const { addItem } = useCart();
  const { inCart } = useCart();
  const [products, setProducts] = useState();
  const pType = props.productType;

  const [paginateInfo, setPaginateInfo] = useState();
  const [page, setPage] = useState(1);
  const [show, setShow] = useState('12');
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");
  const [query, setQuery] = useState('');

  useEffect(() => {
    setQuery('?page=' + page + '&limit=' + show + '&sort_by=' + sortBy.slice(0, -2) + '&sort_type=' + sortType);
  }, [show, sortBy, sortType, page]);

  useEffect(() => {
    setProducts(null);
    if (pType && query) {
      // console.log(query);
    //   if (pType === 'get-products') {
    //   setLoading(true);
    // }
      axios.get("" + pType + query)
        .then(resp => {
          if (pType === 'get-products') {
            // console.log(resp.data.data);
            window.scrollTo(0, 300)
            setPaginateInfo(resp.data.data.meta);
            setProducts(resp.data.data.data);
            setLoading(false);
          }
          else if (pType === 'popular') {
        
            setProducts(resp.data.data);
          }
          else if (pType === 'dealsday') {
          
            setProducts(resp.data.data);
          }
        });
    }
  }, [pType, query]);
 
  var customer_id;
  if (localStorage.getItem("user")) {
    var obj = JSON.parse(localStorage.getItem("user"));
    customer_id = obj.customer_id;
  }
  const addToWishlist = (pID) => {
    if (customer_id == null) {
      navigate("/login?q=You%20Have%20To%20Login%20First%20For%20Wishlist");
      window.location.reload(false);
    }
    else {
      axios.get("add-to-wishlist/" + customer_id + "/" + pID)
        .then(resp => {
         
          if (resp.data.success === true) {
            dispatch(increment());
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Product added to wishlist',
              showConfirmButton: false,
              timer: 800
            });
          }
          else if (resp.data.success === false) {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: resp.data.message
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  // console.log(products);
  // console.log(pType);
  return (
    <>
    {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      {pType === 'get-products' && (
        <div className='row d-flex flex-row-reverse mb-4'>
          <div className='col-12 col-md-8 col-lg-4'>
            <div className='row justify-content-end'>
              <div className='col-4 col-md-6'> <select className="form-control"
                name="show" onChange={(e) => { setShow(e.target.value); }}>
                <option value={null}>Show</option>
                <option value={'12'}>12</option>
                <option value={'24'}>24</option>
                <option value={'36'}>36</option>
                <option value={'48'}>48</option>
                <option value={'60'}>60</option>
              </select>
              </div>
              <div className='col-6'>
                <select className="form-control"
                  name="city"
                  value={sortBy} onChange={(e) => { setSortBy(e.target.value); setSortType(e.target.childNodes[e.target.selectedIndex].getAttribute('type')); }}>
                  <option value={null} type={null}>Sort by</option>
                  <option value={'id-n'} type={'desc'}>Newest</option>
                  <option value={'id-o'} type={'asc'}>Oldest</option>
                  <option value={'product_name-a'} type={'asc'}>Name: (A-Z)</option>
                  <option value={'product_name-d'} type={'desc'}>Name: (Z-A)</option>
                  <option value={'product_price-l'} type={'asc'}>Price:low to high</option>
                  <option value={'product_price-h'} type={'desc'}>Price:high to low</option>
                  {/* <option value={'rating'} type={'desc'}>Most Rated</option> */}
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row product-grid-4 ">
        {products ?
          products.map((product, index) => {
            return (
              <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5" key={index}>
                <div
                  data-wow-delay="0.1s"
                  className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                  style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                >
                  <div className="product-img-action-wrap">
                    <div className="product-img product-img-zoom">
                      <Link to={"/product_details/" + product.id + '/' + product.slug}>
                        {product.image && (

                          <img
                            src={product.image.medium}
                            alt="not found img"
                            className="default-img"
                          />
                        )}
                      </Link>
                    </div>
                    <div className="product-action-1">

                      <Link aria-label="Add To Wishlist" to="" data-url="#" className="action-btn hover-up js-add-to-wishlist-button"
                        onClick={() =>
                          addToWishlist(product.id)
                        }>
                        <i className="fi-rs-heart"></i>
                      </Link>

                    </div>
                    {/* discount percentage */}
                    {product.discount > 0 && (
                      <div className="product-badges product-badges-position product-badges-mrg">
                        <span className="hot">{product.discount}%</span>
                      </div>
                    )}
                    {product.stock <= 0 && (
                      <div className="product-badges product-badges-position product-badges-mrg otstck">
                        <span className="otostck">Out Of Stock!</span>
                      </div>
                    )}
                  </div>
                  <div className="product-content-wrap">
                    <h2>
                      <Link to={"/product_details/" + product.id + '/' + product.slug} title={product.name}>{product.name}</Link>
                    </h2>
                    {/* <div className="product-rate-cover">
                      <div className="product-rate d-inline-block">
                        <div className="product-rating" style={{ width: "80%" }}></div>
                      </div>
                      <span className="font-small ml-5 text-muted"> (3)</span>
                    </div> */}

                    <div className="product-card-bottom row">
                      <div className="col-6 ps-1 col-md-6 text-center">
                        {product.discount > 0 ? (
                          <div className="product-price">
                            <span>{product.formatted_final_product_price}</span>
                            <span className="old-price">{product.formatted_regular_price}</span>
                          </div>
                        ) : (
                          <div className="product-price">
                            <span>{product.formatted_final_product_price}</span>
                            {/* <span className="old-price">{product.formatted_regular_price}</span> */}
                          </div>
                        )}
                      </div>
                      <div className="col-6 pe-0 col-md-6 text-center">
                        <div
                          className="add-cart"
                        >
                          <Link aria-label="Add To Cart" data-id="2" data-url="#" to="" className="action-btn add-to-cart-button add" onClick={() =>{
                            if(product.stock!==null && product.stock>0){
                              if(inCart(product.id)){
                                Swal.fire({
                                  position: 'center',
                                  icon: 'warning',
                                  title: 'The product is already in the cart'
                                });
                              }
                              else{
                                addItem({
                                  id: product.id,
                                  product_id: product.id,
                                  name: product.name,
                                  price: product.final_product_price,
                                  quantity: 1,
                                  image: product.image.small,
                                  stock: product.stock
                                });
                                Swal.fire({
                                  position: 'top-end',
                                  icon: 'success',
                                  title: 'Product added to cart',
                                  showConfirmButton: false,
                                  timer: 800
                                });
                              }
                            }
                            else{
                              Swal.fire({
                                position: 'top-end',
                                icon: 'warning',
                                title: 'Product is out of stock!'
                              });
                            }
                          }
                          }>
                            <i className="fi-rs-shopping-cart mr-5"></i>Add
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }) : (
            <>
            <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
            <div
              data-wow-delay="0.1s"
              className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
              style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
              >
            <Skeleton className="product_skelton_image" /><br/>
            <center><Skeleton className="product_skelton_title"/></center><br/>
            <center>
              <div className="row">
                <div className="col-6 ps-0">
              <Skeleton className="product_skelton_price" inline={true} />
                </div>
                <div className="col-6 pe-0">
              <Skeleton className="product_skelton_add_btn" inline={true} />
                </div>
              </div>
            </center>
            </div>
            
            </div>

            <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
            <div
              data-wow-delay="0.1s"
              className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
              style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
              >
            <Skeleton className="product_skelton_image" /><br/>
            <center><Skeleton className="product_skelton_title"/></center><br/>
            <center>
              <div className="row">
                <div className="col-6 ps-0">
              <Skeleton className="product_skelton_price" inline={true} />
                </div>
                <div className="col-6 pe-0">
              <Skeleton className="product_skelton_add_btn" inline={true} />
                </div>
              </div>
            </center>
            </div>
            </div>

            <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
            <div
              data-wow-delay="0.1s"
              className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
              style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
              >
            <Skeleton className="product_skelton_image" /><br/>
            <center><Skeleton className="product_skelton_title"/></center><br/>
            <center>
              <div className="row">
                <div className="col-6 ps-0">
              <Skeleton className="product_skelton_price" inline={true} />
                </div>
                <div className="col-6 pe-0">
              <Skeleton className="product_skelton_add_btn" inline={true} />
                </div>
              </div>
            </center>
            </div>
            </div>

            <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
            <div
              data-wow-delay="0.1s"
              className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
              style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
              >
            <Skeleton className="product_skelton_image" /><br/>
            <center><Skeleton className="product_skelton_title"/></center><br/>
            <center>
              <div className="row">
                <div className="col-6 ps-0">
              <Skeleton className="product_skelton_price" inline={true} />
                </div>
                <div className="col-6 pe-0">
              <Skeleton className="product_skelton_add_btn" inline={true} />
                </div>
              </div>
            </center>
            </div>
            </div>
            <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
            <div
              data-wow-delay="0.1s"
              className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
              style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
              >
            <Skeleton className="product_skelton_image" /><br/>
            <center><Skeleton className="product_skelton_title"/></center><br/>
            <center>
              <div className="row">
                <div className="col-6 ps-0">
              <Skeleton className="product_skelton_price" inline={true} />
                </div>
                <div className="col-6 pe-0">
              <Skeleton className="product_skelton_add_btn" inline={true} />
                </div>
              </div>
            </center>
            </div>
            </div>
            <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
            <div
              data-wow-delay="0.1s"
              className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
              style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
              >
            <Skeleton className="product_skelton_image" /><br/>
            <center><Skeleton className="product_skelton_title"/></center><br/>
            <center>
              <div className="row">
                <div className="col-6 ps-0">
              <Skeleton className="product_skelton_price" inline={true} />
                </div>
                <div className="col-6 pe-0">
              <Skeleton className="product_skelton_add_btn" inline={true} />
                </div>
              </div>
            </center>
            </div>
            </div>
            <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
            <div
              data-wow-delay="0.1s"
              className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
              style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
              >
            <Skeleton className="product_skelton_image" /><br/>
            <center><Skeleton className="product_skelton_title"/></center><br/>
            <center>
              <div className="row">
                <div className="col-6 ps-0">
              <Skeleton className="product_skelton_price" inline={true} />
                </div>
                <div className="col-6 pe-0">
              <Skeleton className="product_skelton_add_btn" inline={true} />
                </div>
              </div>
            </center>
            </div>
            </div>
            <div className="col-6 col-xxl-3 col-xl-3 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
            <div
              data-wow-delay="0.1s"
              className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
              style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
              >
            <Skeleton className="product_skelton_image" /><br/>
            <center><Skeleton className="product_skelton_title"/></center><br/>
            <center>
              <div className="row">
                <div className="col-6 ps-0">
              <Skeleton className="product_skelton_price" inline={true} />
                </div>
                <div className="col-6 pe-0">
              <Skeleton className="product_skelton_add_btn" inline={true} />
                </div>
              </div>
            </center>
            </div>
            </div>
            </>
          )}
      </div>
      {pType === 'get-products' && (
      <>
      <div className="pagination-area ps-3 mt-20 mb-20 pagination-page">
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-start">
          {paginateInfo && (paginateInfo.links.map((link, index) => {
            return(
            <li key={index} className={link.active ? "page-item active" : "page-item"}>
              {link.label==='&laquo; Previous' ? (<></>
              ) : (
                  <button className="page-link" onClick={()=>{setProducts(null);
                    if(link.label==='Next &raquo;' && paginateInfo.last_page>page){setPage(paginateInfo.current_page+1);} else {setPage(link.label);} }}>{link.label==='Next &raquo;' ? (<i className="fi-rs-arrow-small-right"></i>) : link.label}
                  </button>
              ) }
            </li>
            )
          }))
          }
          </ul>
        </nav>
      </div>
      </>
      )}
    </>
  );
};
export default React.memo(ProductBoxes);

