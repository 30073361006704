import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import { useCart } from "react-use-cart";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import '../assets/Css/dailyBestSales.css';
import { useDispatch } from "react-redux";
import { increment } from "../store/Counter";
import Skeleton from "react-loading-skeleton";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "block", background: "black" }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "block", background: "black" }} onClick={onClick} />;
}

const BestSellProducts = () => {
  
  const { inCart } = useCart();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [products, setProducts] = useState(null);
  useEffect(() => {
    // setProducts(null);
    if(products==null){
      axios
        .get("bestseller")
        .then((response) => {
          setProducts(response.data.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);
  
  var settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var customer_id;
  if (localStorage.getItem("user")) {
    var obj = JSON.parse(localStorage.getItem("user"));
    customer_id = obj.customer_id;
    // console.log(obj);
  }
  const addToWishlist = (pID) => {
    // console.log('wishlist click:  '+pID);
    if(customer_id==null){
      navigate("/login?q=You%20Have%20To%20Login%20First%20For%20Wishlist");
      window.location.reload(false);
    }
    else{
      axios.get("add-to-wishlist/" + customer_id + "/" + pID)
        .then(resp => {
          if(resp.data.success===true){
            dispatch(increment());
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Product added to wishlist',
              showConfirmButton: false,
              timer: 800
            });
          }
          else if(resp.data.success===false){
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: resp.data.message,
              // showConfirmButton: false,
              // timer: 800
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // console.log(pID);
  }

  const { addItem } = useCart();
  return (
    <div className="row p-0">
      {!products ? (
        <Slider {...settings} className='p-0'>
        <div className="slider-gap">
        <div className="col-6 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
        <div
          data-wow-delay="0.1s"
          className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
          style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn", width: '310px' }}
          >
        <Skeleton className="product_skelton_image" style={{ width: '260px', height: '300px' }} /><br/>
        <center><Skeleton className="product_skelton_title"/></center>
        <center>
          <div className="row">
            <div className="col-6 ps-0">
          <Skeleton className="product_skelton_price" inline={true} />
            </div>
          </div>
          
          <Skeleton borderRadius={25} style={{width:'89%'}} height={42} /><br/>
        </center>
        </div>
        
        </div>
        </div>
        <div className="slider-gap">
        <div className="col-6 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
        <div
          data-wow-delay="0.1s"
          className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
          style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn", width: '310px' }}
          >
        <Skeleton className="product_skelton_image" style={{ width: '310px', height: '300px' }} /><br/>
        <center><Skeleton className="product_skelton_title"/></center>
        <center>
          <div className="row">
            <div className="col-6 ps-0">
          <Skeleton className="product_skelton_price" inline={true} />
            </div>
          </div>
          
          <Skeleton borderRadius={25} style={{width:'89%'}} height={42} /><br/>
        </center>
        </div>
        
        </div>
        </div>
        <div className="slider-gap">
        <div className="col-6 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
        <div
          data-wow-delay="0.1s"
          className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
          style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn", width: '310px' }}
          >
        <Skeleton className="product_skelton_image" style={{ width: '310px', height: '300px' }} /><br/>
        <center><Skeleton className="product_skelton_title"/></center>
        <center>
          <div className="row">
            <div className="col-6 ps-0">
          <Skeleton className="product_skelton_price" inline={true} />
            </div>
          </div>
          
          <Skeleton borderRadius={25} style={{width:'89%'}} height={42} /><br/>
        </center>
        </div>
        
        </div>
        </div>
        <div className="slider-gap">
        <div className="col-6 col-lg-4 col-md-6 mb-lg-0 mb-md-5">
        <div
          data-wow-delay="0.1s"
          className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
          style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn", width: '310px' }}
          >
        <Skeleton className="product_skelton_image" style={{ width: '310px', height: '300px' }} /><br/>
        <center><Skeleton className="product_skelton_title"/></center>
        <center>
          <div className="row">
            <div className="col-6 ps-0">
          <Skeleton className="product_skelton_price" inline={true} />
            </div>
          </div>
          
          <Skeleton borderRadius={25} style={{width:'89%'}} height={42} /><br/>
        </center>
        </div>
        
        </div>
        </div>
        </Slider>
      ) : (
      <Slider {...settings} className='p-0'>
        {products &&
          products.map((product, index) => {
            return (
              <div className="slider-gap" key={index}>
                <div
                  data-wow-delay="0.1s"
                  className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                  style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                >
                  <div className="product-img-action-wrap">
                    <div className="product-img product-img-zoom">
                      <Link to={"/product_details/" + product.id + '/' + product.slug}>
                        {product.image && (

                          <img
                            src={product.image.medium}
                            alt="not found img"
                            className="default-img"
                          />
                        )}
                      </Link>
                    </div>
                    <div className="product-action-1">

                      <Link aria-label="Add To Wishlist" to="" data-url="#" className="action-btn hover-up js-add-to-wishlist-button"
                        onClick={() =>
                          addToWishlist(product.id)
                        }>
                        <i className="fi-rs-heart"></i>
                      </Link>

                    </div>
                    {/* discount percentage */}
                    {product.discount > 0 && (
                      <div className="product-badges product-badges-position product-badges-mrg">
                        <span className="hot">{product.discount}%</span>
                      </div>
                    )}
                    {product.stock <= 0 && (
                      <div className="product-badges product-badges-position product-badges-mrg otstck">
                        <span className="otostck">Out Of Stock!</span>
                      </div>
                    )}
                  </div>
                  <div className="product-content-wrap">
                    <h2>
                      <Link to={"/product_details/" + product.id + '/' + product.slug}>{product.name}</Link>
                    </h2>
                  
                    {product.discount > 0 ? (
                      <div className="product-price mb-3">
                        <span>{product.formatted_final_product_price}</span>
                        <span className="old-price">{product.formatted_regular_price}</span>
                      </div>
                    ) : (
                      <div className="product-price mb-3">
                        <span>{product.formatted_final_product_price}</span>
                        {/* <span className="old-price">{product.formatted_regular_price}</span> */}
                      </div>
                    )}
                    <a aria-label="Add To Cart" data-id="15"  href="#" className="action-btn add-to-cart-button btn w-100 hover-up"
                    onClick={() => {
                      if(product.stock!==null && product.stock>0){
                        if(inCart(product.id)){
                          Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'The product is already in the cart'
                          });
                        }
                        else{
                          addItem({
                            id: product.id,
                            product_id: product.id,
                            name: product.name,
                            price: product.final_product_price,
                            quantity: 1,
                            image: product.image.small,
                            stock: product.stock
                          });
                          Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Product added to cart',
                            showConfirmButton: false,
                            timer: 800
                          });
                        }
                      }
                      else{
                        Swal.fire({
                          position: 'top-end',
                          icon: 'warning',
                          title: 'Product is out of stock!'
                        });
                      }
                    }
                    }><i className="fi-rs-shopping-cart mr-5"></i>Add To Cart </a>
                    
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
      ) 
      }
    </div>
  );
}
export default BestSellProducts;
