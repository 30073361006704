import { createSlice } from "@reduxjs/toolkit";

export const FooterAdvertisementDataSlice = createSlice({
  name: "FooterAdvertisementData",
  initialState: {
    FooterAdvertisementData: [],
  },
  reducers: {
    setFooterAdvertisementData: (state, action) => {
      state.FooterAdvertisementData = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setFooterAdvertisementData } = FooterAdvertisementDataSlice.actions;

export default FooterAdvertisementDataSlice.reducer;

