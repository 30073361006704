import axios from "axios";
import React, { useEffect, useState } from "react";
// import Carousel from "react-bootstrap/Carousel";
import { Carousel } from 'react-bootstrap';
import { Link } from "react-router-dom";
import '../assets/Css/banner.css';
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

const HomeBannerSlider  = () => {
  const { HomeBannerData } = useSelector((state) => state.HomeBannerData);
//  console.log(banners);
    return (
      <>
      <Carousel className="pt-lg-4">
      {HomeBannerData && HomeBannerData[0] ?
        HomeBannerData.map((banner) => {
          return (
            
            banner.link && banner.link.length>1 ? (
            <Carousel.Item key={banner.id} className='p-2'>
              
              <Link to={'/flash-sell'}>
                  <img className="d-block top-slider-img banner_image" src={axios.defaults.baseURL.slice(0, -4) + "frontend/images/banner_images/" + banner.banner_image} alt={banner.title} />
              <Carousel.Caption>
                <div className="top-left">
                  <div className="banner_text_div">
                    {banner.title && (
                    <h1 className="display-22 mb-40 banner_text">
                      {banner.title}
                    </h1>
                    )}
                    {banner.sub_title && (
                    <p className="mb-65 banner_text banner_sub_text">{banner.sub_title}</p>
                    )}
                 
                  </div>

                </div>
              </Carousel.Caption>
            </Link>
            </Carousel.Item>
              ): (
                <Carousel.Item key={banner.id} className='p-2'>
                <img className="d-block top-slider-img banner_image" src={axios.defaults.baseURL.slice(0, -4) + "frontend/images/banner_images/" + banner.banner_image} alt={banner.title} />
            <Carousel.Caption>
              <div className="top-left">
                <div className="banner_text_div">
                  <h1 className="display-22 mb-40 banner_text">
                    {banner.title}
                  </h1>
                  <p className="mb-65 banner_text banner_sub_text">{banner.sub_title}</p>
               
                </div>

              </div>
            </Carousel.Caption>
          </Carousel.Item>
              )
            
          );
        }) : (
          <Skeleton borderRadius={25} className={'mt-2 p-2 banner_image'} />
        )}

    </Carousel>
      </>
    );
  
}

export default HomeBannerSlider;
