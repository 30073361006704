import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../layouts/Layout";
import Swal from "sweetalert2";

const OtpVerify = () => {
  useEffect(() => {
    document.title = "Ultimate Organic Life - Otp Verification";
  }, []);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);

  const { id } = useParams();
  const [errorList, setError] = useState([]);
  let navigate = useNavigate();
  let [otp, setOTP] = useState("");
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = () => {
    setMinutes(3);
    setSeconds(0);

    axios.get("otp-resend/" + id)
      .then(resp => {
        if(resp.data.message){
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: resp.data.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (event) => {

    axios
      .post("otp-verification/" + id, { otp: otp })
      .then(function (resp) {
        // console.log('then er vitor');
        // console.log(resp.data);
        if (resp.data.success === true) {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Your Phone Number Verified Successfully',
            showConfirmButton: false,
            timer: 800
          });
          setTimeout(() => {
            navigate("/login");
            window.location.reload(false);
          }, 800);
          // navigate("/login");
        }
        else if(resp.data.success === false){
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: resp.data.message,
            showConfirmButton: true
          });
        }
        else {
          setError(resp.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    event.preventDefault();
  };
  return (
    <div>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <main className="main" id="main-section">
          <div className="page-header breadcrumb-wrap">
            <div className="breadcrumb">
              <Link to="/" itemProp="item" title="Home">
                Home
                <meta itemProp="name" content="Home" />
                <meta itemProp="position" content="1" />
              </Link>
              <span></span> OTP Verification
              <meta itemProp="name" content="OTP Verification" />
              <meta itemProp="position" content="2" />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <div className="login_wrap widget-taber-content background-white">
                <div className="padding_eight_all bg-white">
                  <div className="padding_eight_all bg-white">
                    <h3 className="mt-20">OTP Verification</h3>
                    {/* <p className="mb-20">
                      Resend OTP?{" "}
                      <Link to="/login" className="d-inline-block">
                        Resend
                      </Link>
                    </p> */}
                    <div className="mb-20 countdown-text">
                      {seconds > 0 || minutes > 0 ? (
                        <span>
                          Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </span>
                      ) : (
                        <span>Didn't recieve code?</span>
                      )}

                      <Link className="ps-3 d-inline-block" to={''}
                        disabled={seconds > 0 || minutes > 0}
                        style={{
                          color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "",
                        }}
                      onClick={seconds > 0 || minutes > 0 ? "" : resendOTP}
                      >
                        Resend OTP
                      </Link>
                    </div>
                    <p>Please fill in the information below</p>
                  </div>
                  <form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <div className="form__content">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="otp"
                          id="otp"
                          placeholder="OTP"
                          value={otp}
                          onChange={(e) => setOTP(e.target.value)}
                        />
                        <span className='text-danger ps-1'>{errorList.otp}</span>
                      </div>

                      <div className="form-group">
                        <button type="submit" className="btn btn-fill-out btn-block hover-up">
                          Verify
                        </button>
                      </div>
                      <br />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>

    </div>
  );
};

export default OtpVerify;
