import { createSlice } from "@reduxjs/toolkit";

export const HomeBannerDataSlice = createSlice({
  name: "HomeBannerData",
  initialState: {
    HomeBannerData: [],
  },
  reducers: {
    setHomeBannerData: (state, action) => {
      state.HomeBannerData = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setHomeBannerData } = HomeBannerDataSlice.actions;

export default HomeBannerDataSlice.reducer;

