import React from 'react';
import { hydrate } from 'react-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/Store.js';
import './assets/Css/loadingAnimation.css'
import 'react-loading-skeleton/dist/skeleton.css'
import axios from "axios";
import { CartProvider } from 'react-use-cart';

axios.defaults.baseURL = "https://ecommerceapi.solvestone.com/api/";
// axios.defaults.baseURL="https://ultimateapi.hostprohub.com/api/";
// axios.defaults.baseURL="https://kodeeo.net/ultimate-api/public/api/";
// axios.defaults.baseURL="http://127.0.0.1:8000/api/";
// axios.defaults.baseURL="http://192.168.100.11:8080/api/";
axios.defaults.headers.common["Accept"] = 'application/json';
axios.defaults.headers.common["retry-after"] = 3600;
var token = null;
if (localStorage.getItem("user")) {
  var obj = JSON.parse(localStorage.getItem("user"));
  token = obj.token;
  axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
  // axios.defaults.headers.common["Authorization"] = token;
  // axios.defaults.headers.common["bearerToken"] = token;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <CartProvider>
//       <App />
//       </CartProvider>
//     </Provider>
//   </React.StrictMode>
// );

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<React.StrictMode>
    <Provider store={store}>
      <CartProvider>
        <App />
      </CartProvider>
    </Provider>
  </React.StrictMode>, rootElement);
} else {
  root.render(<React.StrictMode>
    <Provider store={store}>
      <CartProvider>
        <App />
      </CartProvider>
    </Provider>
  </React.StrictMode>, rootElement);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
