import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/Css/productDetails.css"
import ProductBoxes from "../components/ProductBoxes";
import Modal from "react-bootstrap/Modal";
import { useCart } from "react-use-cart";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import Layout from "../layouts/Layout";
import Parser from 'html-react-parser';
import VideoIcon from "../assets/images/logo/video-clip.png"
import { useDispatch } from "react-redux";
import { increment } from "../store/Counter";
import Skeleton from "react-loading-skeleton";
import SEO from "../components/Seo";

const ProductDetails = () => {
  
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);
  
  let navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);
  const [altText, setAltText] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { id } = useParams();
  const [product, setProduct] = useState();
  const [description, setDescription] = useState();
  const [attributes, setAttributes] = useState();
  //   const [activeImg, setActiveImg] = useState("");
  const [selectedAttribute, setSelectedAttribute] = useState({
    id: '', value: '', stock: '', attribute_final_price: '', old_price: ''
  });
  const [stock, setStock] = useState(null);
  const [productImg, setProductImg] = useState();
  const [productVideo, setProductVideo] = useState();

  useEffect(() => {
    setProductImg(null);
    setSelectedAttribute({
      id: '', value: '', stock: '', attribute_final_price: '', old_price: ''
    });
    setStock(null);
    setAttributes(null);
    setAttributeWisePrice(null);
    // setLoading(true);
    axios.get("get-product/" + id)
    .then(resp => {
        // setLoading(false);
        setProduct(resp.data.data);
        setDescription(resp.data.data.description);
        setProductImg(resp.data.data.image.large);
        setAttributes(resp.data.data.attributes);
        setAltText(resp.data.data.alter_text ?? '');
    });
   
    window.scrollTo(0, 0);
  }, [id]);
  // console.log(product);
  const [attributeWisePrice, setAttributeWisePrice] = useState(null);

  const PreviousBtn = (props) => {
    const { className, onClick } = props;
    return <div className={className} onClick={onClick}></div>;
  };
  const NextBtn = (props) => {
    const { className, onClick } = props;
    return <div className={className} onClick={onClick}></div>;
  };

  let { addItem } = useCart();


  const imageSrc = (e) => {
    setAltText(e.target.alt);
    setProductVideo(null);
    setProductImg(e.target.src);
    // setActiveImg("slick-current slick-active")

  }
  const videoSrc = (videoLink) => {

    // console.log(videoLink);
    setProductVideo(videoLink);
    // setActiveImg("slick-current slick-active")

  }
  const buynow = () => {
    if(selectedAttribute && selectedAttribute.id){
      navigate("/checkout/" + id + "/" + quantity + "/" + selectedAttribute.id);
    }
    else{
      navigate("/checkout/" + id + "/" + quantity);
    }

  }
  var customer_id;
  if (localStorage.getItem("user")) {
    var obj = JSON.parse(localStorage.getItem("user"));
    customer_id = obj.customer_id;
    // console.log(obj);
  }
  const addToWishlist = (pID) => {
    // console.log('wishlist click:  '+pID);
    if (customer_id == null) {
      navigate("/login?q=You%20Have%20To%20Login%20First%20For%20Wishlist");
      window.location.reload(false);
    }
    else {
      axios.get("add-to-wishlist/" + customer_id + "/" + pID)
        .then(resp => {
          // console.log(resp.data.success);
          // console.log(resp.data);
          if (resp.data.success == true) {
            dispatch(increment());
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Product added to wishlist',
              showConfirmButton: false,
              timer: 800
            });
          }
          else if (resp.data.success == false) {
            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              title: resp.data.message,
              // showConfirmButton: false,
              // timer: 800
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // console.log(pID);
  }

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [{
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://ultimateorganiclife.com/"
    },{
      "@type": "ListItem",
      "position": 2,
      "name": "Products",
      "item": "https://ultimateorganiclife.com/products"
    },{
      "@type": "ListItem",
      "position": 3,
      "name": product && product.category && (product.category),
      "item": product && product.category && ("https://ultimateorganiclife.com/product-categories/" + product.category_id+"/"+product.category)
    },{
      "@type": "ListItem",
      "position": 4,
      "name": product && (product.name)
    }]
  };

  return (
    <div>
      {/* {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )} */}
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      {product && (
      <SEO title={product.name ? 'Ultimate Organic Life | '+product.name : 'Ultimate Organic Life | Product View'} meta_title={product.meta_title ? product.meta_title : ""} 
      meta_description={product.meta_description ? product.meta_description : ""} keywords={product.meta_keywords ? product.meta_keywords : ""}
      name={'Ultimate Organic Life'} type={'ecommerce.product'} meta_image={product.og_image ? product.og_image : "Product Og Image"} />
      )}

      <Layout>
        <main className="main" id="main-section">
          <div className="page-header breadcrumb-wrap">
            <div className="breadcrumb" itemScope itemType="">
              <Link to="/" itemProp="item" title="Home">
                Home
                <meta itemProp="name" content="Home" />
                <meta itemProp="position" content="1" />
              </Link>
              <span></span>
              <Link to="/products" itemProp="item" title="Products">
                Products
                <meta itemProp="name" content="Products" />
                <meta itemProp="position" content="2" />
              </Link>
              <span></span>
              {product && product.category && (
                <>
                <Link to={"/product-categories/" + product.category_id+"/"+product.category} itemProp="item" title={product.category}>
                  {product.category}

                  <meta itemProp="name" content={product.category} />
                  <meta itemProp="position" content="3" />
                </Link>
                <span></span> {product.name}
                <span></span>
                </>
              )}
              
              <meta itemProp="position" content="4" />
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col-lg-12 m-auto">
                <div className="product-detail accordion-detail">
                  <div className="row mb-50 mt-30">
                    <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                      <div className="detail-gallery">
                        <span className="zoom-icon">
                          <i className="fi-rs-search"></i>
                        </span>

                        <div className="product-image-slider">
                          <figure className="border-radius-10">
                            <div className="">
                              <Slider
                                autoplaySpeed={2000}
                                dots
                                initialSlide={2}
                                infinite
                                prevArrow={<PreviousBtn />}
                                nextArrow={<NextBtn />}
                                customPaging={product && (
                                  <div>
                                    <img
                                      src={productImg}
                                      alt=""
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        objectFit: "cover",

                                      }}
                                    />
                                  </div>
                                )}
                                dotsclassName="slick-dots custom-indicator"
                              >
                                <div>
                                  <div onClick={handleShow}>
                                    {productVideo ? (
                                      // <video controls autoPlay width={652} height={652}>
                                      //   <source src={product.product_video} type="video/mp4" />
                                      // </video>
                                      <iframe width={650} height={658} className='video_frame' src={product.video+'?autoplay=1'} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
                                    ) :
                                      (
                                        productImg ? (
                                          <>
                                          <img
                                          src={productImg}
                                          alt={altText}
                                          style={{ width: "100%" }}/>
                                          </>

                                          ) :
                                          (
                                            <Skeleton inline={true} width={'100%'} height={560} style={{top:'-5px'}} />
                                          )
                                      )}
                                  </div>
                                </div>
                              </Slider>
                              <div onClick={handleClose}>
                                <Modal show={show}>
                                  <Modal.Header closeButton></Modal.Header>
                                  <Modal.Body>
                                    <div>
                                      <img
                                        src={productImg}
                                        alt=""
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  </Modal.Body>
                                </Modal>
                              </div>
                            </div>
                          </figure>
                        </div>

                        <div className="slider-nav-thumbnails" style={{ display: "flex" }}>
                          <div>
                            <div style={{ textAlign: "center" }}>
                              <div className="slider-nav-thumbnails slick-initialized slick-slider">
                                <div className="slick-list draggable">
                                  <div className="slick-track" style={{ opacity: "1", width: "100%", transform: "translate3d(0px, 0px, 0px)" }}>
                                    {product && (
                                      <div
                                        className={"slick-slide preview_image mt-1"}
                                        data-slick-index="0"
                                        aria-hidden="false"
                                        tabIndex="0"
                                        style={{ width: "80px", height: "80px" }}
                                      >
                                        <img className={product.image && (productImg===product.image.large ? "active" : "")}
                                          src={product.image && (product.image.large)}
                                          alt="Product" onClick={imageSrc}
                                        />
                                      </div>
                                    )}
                                    
                                    {product && product.multiple_images &&
                                      product.multiple_images.map((image, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className={"slick-slide preview_image mt-1"}
                                            data-slick-index="0"
                                            aria-hidden="false"
                                            tabIndex="0"
                                            style={{ width: "80px", height: "80px" }}
                                          >
                                            <img
                                              src={axios.defaults.baseURL.slice(0, -4) + "frontend/images/product_images/large/" + image.image}
                                              alt={image.alter_txt ?? ''} onClick={imageSrc}
                                            />
                                          </div>
                                        );
                                      })}
                                    {product && product.video && product.video !== null &&
                                      (
                                        <div
                                          className={"slick-slide preview_image"}
                                          data-slick-index="0"
                                          aria-hidden="false"
                                          tabIndex="0"
                                          style={{ width: "80px" }}
                                        >
                                          <img
                                            src={VideoIcon}
                                            alt="Video Icon" onClick={() => videoSrc(axios.defaults.baseURL.slice(0, -4) + "frontend/videos/product_videos/" + product.product_video)}
                                          />
                                        </div>
                                      )
                                    }
                                  </div>
                                </div>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single-social-share clearfix mt-50 mb-15">
                        <p className="mb-15 mt-30 font-sm">
                          <i className="fi-rs-share me-2"></i>
                          <span className="d-inline-block">Share this</span>
                          </p>
                          <div className="mobile-social-icon wow fadeIn mb-sm-5 mb-md-0 animated animated" style={{visibility: "visible", justifyContent: "flex-start"}}>
                            <a href={"https://www.facebook.com/sharer/sharer.php?u="+window.location.href} target="_blank" className="facebook">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path>
                              <path fill="#ffffff" d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z"></path>
                              </svg>
                              </a>
                              <a href={"https://twitter.com/intent/tweet?url="+window.location.href+"&text="+(product && product.meta_description ? product.meta_description : "Description")} target="_blank" className="twitter">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path>
                                <path fill="#ffffff" d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"></path>
                                </svg></a>
                                <a href={"https://www.linkedin.com/shareArticle?mini=true&url="+window.location.href+"&summary="+(product && product.meta_description ? product.meta_description : "Meta Description")} target="_blank" className="linkedin"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="#ffffff" d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 0 1-1.548-1.549 1.548 1.548 0 1 1 1.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z"></path>
                                </svg></a>
                                </div>
                      </div>
                      <a href={"mailto:someone@example.com?subject=Buy "+(product ? product.name:'product name')+"&body=Buy this one: "+window.location.href} className="mail-to-friend font-sm color-grey"><i className="fi-rs-envelope"></i> Email to a Friend</a>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <div className="detail-info pr-30 pl-30">
                        <h2 className="title-detail">
                          {" "}
                          {product ? (
                            <div>
                              {product.name}
                            </div>
                          ) : (
                            <div>
                            <Skeleton inline={true} height={40} width={'90%'} />
                            </div>
                          )}
                        </h2>
                        {/* <div className="product-detail-rating">
                          <div className="product-rate-cover text-end">
                            <div className="product-rate d-inline-block">
                              <div className="product-rating" style={{ width: "73.33%" }}></div>
                            </div>
                            <span className="font-small ml-5 text-muted">(3 reviews)</span>
                          </div>
                        </div> */}
                        {product ? (
                        <div className="clearfix product-price-cover">
                          <div className="product-price primary-color float-left">
                            
                          {product ? (
                            <>
                            <span className="current-price text-brand">
                              {" "}
                                <div>৳{product.product_discount > 0 ? ((attributeWisePrice>0 ? attributeWisePrice : product.final_product_price)) : (attributeWisePrice>0 ? attributeWisePrice : product.final_product_price)}</div>
                              
                            </span>
                            </>
                            ) : (
                                <Skeleton inline={true} />
                              )}

                            {product && product.discount > 0 ? (
                              <span>
                                <span className="save-price font-md color3 ml-15">
                                  <span className="percentage-off">-{product.discount}% Off</span>
                                </span>
                                <span className="old-price font-md ml-15">{attributeWisePrice>0 ? selectedAttribute.old_price : product.formatted_regular_price}</span>

                              </span>
                            ) : (
                              <Skeleton inline={true} />
                            )}
                          </div>
                        </div>
                        ) : (
                          <><Skeleton inline={true} height={35} width={'25%'} className={"mt-4 mb-4"} /></>
                        )}
                        <div className="short-desc mb-30">

                          {product ? (
                            <div>{product.short_description}</div>
                          ) : (
                            <div><Skeleton inline={true} height={15} width={'90%'} count={5} /></div>
                          )}

                        </div>
                        {attributes ? 
                        <div className="pr_switch_wrap">
                          <div className="product-attributes">
                            <div className="text-swatches-wrapper attribute-swatches-wrapper attribute-swatches-wrapper form-group product__attribute product__color" data-type="text">
                              <label className="attribute-name"><b>Variation</b></label>
                              <div className="attribute-values">
                                <ul className="text-swatch attribute-swatch color-swatch">
                                {attributes.map((attribute, index) => {
                            
                                  return (
                               
                                      <li key={index} className="attribute-swatch-item" onClick={() => {setSelectedAttribute({id:attribute.id, value: attribute.attribute_value, stock: attribute.stock, attribute_final_price: attribute.attribute_final_price, old_price: attribute.attribute_price });
                                      console.log(selectedAttribute.id); setAttributeWisePrice(attribute.attribute_final_price); setStock(attribute.stock); }}>
                                      <div>
                                        <label>
                                        <input className="product-filter-item" type="radio" name="attribute_weight" value={attribute.attribute_value} />
                                         <span className={attribute.id === selectedAttribute.id ? "attribute_css active" : "attribute_css"}>
                                            {attribute.attribute_value}
                                          </span>
                                        </label>
                                      </div>
                                    </li>
                                    // )
                                  );
                                })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        : (
                          <div style={{marginBottom:'1.2rem'}}><Skeleton width={100} height={20} /> <Skeleton width={100} inline={true} height={35} /> <Skeleton width={100} height={35} inline={true} /> </div>
                        )
                        }
                        {/* {attributes && ( */}
                        <div style={{ marginBottom: "20PX" }}>
                          {product ? (
                                <>
                          <label>Availability: </label>
                          <span className="number-items-available">
                          {stock>0 ? (<span className="text-success"> {stock} products available</span>)
                          : (product && product.stock>0 ? (<span className="text-success"> {product.stock} products available</span>) : (<span className="text-danger"> Out Of Stock!</span>))
                          }
                          </span>
                          </>
                          ) : (
                            <><Skeleton inline={true} width={200} height={20} />{" "}</>
                          )}
                        </div>
                        {/* )} */}

                        <div className="add-to-cart-form" method="post" action="">
                          {/* <input type="hidden" name="_token" value="OyjpfNSnlObnLmlEBFvnYkBtfUADVxZJMFwT4pDb" />
                          <input type="hidden" name="id" className="hidden-product-id" value="29" /> */}
                          <div className="detail-extralink mb-50">
                            {product ? (
                            <div className="">
                            <div
                              className="quantity_button_group">
                              <button
                                className="quantity_button"
                                disabled={quantity <= 1} onClick={() => { setQuantity(Number(quantity) - 1); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="2px" viewBox="0 0 12 1.5">
                                  <rect data-name="Rectangle 970" width="12px" height="2px" fill="currentColor"></rect>
                                </svg>
                              </button>
                              <input type="number" id='quantity' name="quantity" className="qty-input quantity_value" readOnly min={1} step={1} value={quantity}  onChange={(e) => setQuantity(e.target.value)} />
                              {product && (
                              <button
                                className="quantity_button" disabled={selectedAttribute.stock == quantity || !selectedAttribute.stock && product.stock === quantity} onClick={() => { setQuantity(Number(quantity) + 1); }}>
                                <svg data-name="plus (2)" xmlns="http://www.w3.org/2000/svg" width="12px" height="12px"
                                  viewBox="0 0 12 12">
                                  <g data-name="Group 5367">
                                    <path data-name="Path 17138"
                                      d="M6.749,5.251V0h-1.5V5.251H0v1.5H5.251V12h1.5V6.749H12v-1.5Z"
                                      fill="currentColor"></path>
                                  </g>
                                </svg>
                              </button>
                              )}
                            </div>
                            </div>
                            ) : (
                              <><Skeleton inline={true} height={40} width={'30%'} /></>
                            )}

                            <br />
                            <br />
                            <div className="product-extra-link2  has-buy-now-button ">
                              {product ?
                                (
                                  <div>
                                    <div style={{ opacity: '100%' }}>
                                      <button

                                        type="submit"
                                        className="button button-add-to-cart "
                                        onClick={() => {
                                          if (attributes && selectedAttribute.value && selectedAttribute.stock>0 || !attributes && product.stock>0) {
                                            addItem({
                                              variation: selectedAttribute,
                                              name: product.name,
                                              image:product.image.small,
                                              price: product.discount > 0 ? ((attributeWisePrice>0 ? attributeWisePrice : product.final_product_price)) : (attributeWisePrice>0 ? attributeWisePrice : product.final_product_price),
                                              product_id: id,
                                              id: selectedAttribute ? id+selectedAttribute.value : id,
                                            },quantity);
                                            Swal.fire({
                                              position: 'top-end',
                                              icon: 'success',
                                              title: 'Product added to cart',
                                              showConfirmButton: false,
                                              timer: 800
                                            })
                                          }
                                          else if(!attributes && product.stock<=0) {
                                            Swal.fire('This Product Is Out Of Stock')
                                          }
                                          else if(attributes && !selectedAttribute.value) {
                                            Swal.fire('Select Variation First')
                                          }
                                          else if(attributes && selectedAttribute.stock<=0) {
                                            Swal.fire('This Product Is Out Of Stock')
                                          }
                                        }
                                        }
                                      >
                                        <i className="fi-rs-shopping-cart"></i>Add to cart
                                      </button>
                                      <button style={{ marginLeft: "5px" }} className="button button-buy-now " type="submit" name="checkout"

                                        onClick={() => {
                                          if (attributes && selectedAttribute.value && selectedAttribute.stock>0 || !attributes && product.stock>0) {
                                            
                                            buynow()
                                          }
                                          else if(!attributes && product.stock<=0) {
                                            Swal.fire('This Product Is Out Of Stock')
                                          }
                                          else if(attributes && !selectedAttribute.value) {
                                            Swal.fire('Select Variation First')
                                          }
                                          else if(attributes && selectedAttribute.stock<=0) {
                                            Swal.fire('This Product Is Out Of Stock')
                                          }
                                        }
                                        }>
                                        Buy Now
                                      </button>
                                      <Link
                                        aria-label="Add To Wishlist"
                                        className="action-btn hover-up js-add-to-wishlist-button"
                                        to="#"
                                        onClick={() =>
                                          addToWishlist(product.id)
                                        }
                                      >
                                        <i className="fi-rs-heart"></i>
                                      </Link>
                                    </div>
                                  </div>
                                )
                              : (
                                <div style={{marginBottom:'0.8rem'}}> <Skeleton width={165} inline={true} height={45} /> <Skeleton width={120} height={45} inline={true} /> </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="font-xs">
                          <ul className="mr-50 float-start">
                            <li className="mb-5">
                              {product ? (
                                <>
                              <span className="d-inline-block">Categories:&nbsp;</span>
                                <Link to={"/product-categories/" + product.category_id+"/"+product.category} title={product.category}>
                                  {product.category}
                                </Link>{" "}
                                </>
                              ) : (
                                <><Skeleton inline={true} width={200} />{" "}</>
                              )}
                            </li>
                            <li className="mb-5">
                            {product ? (
                                <>
                              <span className="d-inline-block">Tags:&nbsp;</span>
                              <Link to={"#" + product.tags} title={product.tags}>
                                {product.tags}
                              </Link>{" "}
                              </>
                              ) : (
                                <><Skeleton inline={true} width={200} />{" "}</>
                              )}
                            </li>
                            <li className="mb-5">
                            {product ? (
                                <>
                              <span className="d-inline-block">Brands:&nbsp;</span>
                              <Link to={"#" + product.brand} title={product.brand}>
                                {product.brand}
                              </Link>{" "}
                              </>
                              ) : (
                                <><Skeleton inline={true} width={200} />{" "}</>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-info">
                    <div className="tab-style3">
                      <ul className="nav nav-tabs text-uppercase">
                        <li className="nav-item">
                          <Link className="nav-link active" id="Description-tab" data-bs-toggle="tab" to="#Description">
                            Description
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link className="nav-link" id="Reviews-tab" data-bs-toggle="tab" to="#Reviews">
                            Reviews (3)
                          </Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <Link className="nav-link" data-bs-toggle="tab" to="#tab-vendor">
                            Vendor
                          </Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <Link className="nav-link" data-bs-toggle="tab" to="#tab-faq">
                            Questions and Answers
                          </Link>
                        </li> */}
                      </ul>
                      <div className="tab-content shop_info_tab entry-main-content">
                        <div className="tab-pane fade show active" id="Description">
                          {/* <p>
                            {apiData.product &&
                              apiData.product.map((apiDetails, index) => {
                                return <div key={apiDetails.id}>{apiDetails.short_description}</div>;
                              })}
                          </p> */}
                          {description ? (
                            Parser(description)
                            // products.description
                          ) : (
                            <> <Skeleton count={13}/> </>
                          )}
                          <br />
                          <div className="facebook-comment">
                            <div className="fb-comments" data-to="#" data-numposts="5" data-width="100%"></div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="tab-vendor">
                          <div className="vendor-logo d-flex mb-30">
                            <img src="" alt="GoPro" />
                            <div className="vendor-name ml-15">
                              <h6>
                                <Link to="#">GoPro</Link>
                              </h6>
                              <div className="product-rate-cover text-end">
                                <div className="product-rate d-inline-block">
                                  <div className="product-rating" style={{ width: "61%" }}></div>
                                </div>
                                <span className="font-small ml-5 text-muted"> (20 reviews)</span>
                              </div>
                            </div>
                          </div>
                          <ul className="contact-infor mb-50">
                            <li>
                              <img src="" alt="Address" />
                              <strong>Address: </strong>
                              <span>98424 Thiel Squares, East Valentin, Ohio, Puerto Rico</span>
                            </li>
                            <li>
                              <img src="" alt="Contact Seller" />
                              <strong>Contact Seller:</strong>
                              <span>+14049242649</span>
                            </li>
                          </ul>
                          <div>
                            Libero vel eius adipisci saepe sequi blanditiis reiciendis molestiae. Rerum eos ipsam iure est officiis asperiores
                            blanditiis rem.
                          </div>
                        </div>
                        <div className="tab-pane fade faqs-list" id="tab-faq">
                          <div className="accordion" id="faq-accordion">
                            <div className="card">
                              <div className="card-header" id="heading-faq-0">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link btn-block text-left "
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse-faq-0"
                                    aria-expanded="true"
                                    aria-controls="collapse-faq-0"
                                  >
                                    What Shipping Methods Are Available?
                                  </button>
                                </h2>
                              </div>
                              <div id="collapse-faq-0" className="collapse  show " aria-labelledby="heading-faq-0" data-parent="#faq-accordion">
                                <div className="card-body">
                                  Ex Portland Pitchfork irure mustache. Eutra fap before they sold out literally. Aliquip ugh bicycle rights actually
                                  mlkshk, seitan squid craft beer tempor.
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="heading-faq-1">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link btn-block text-left  collapsed "
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse-faq-1"
                                    aria-expanded="true"
                                    aria-controls="collapse-faq-1"
                                  >
                                    Do You Ship Internationally?
                                  </button>
                                </h2>
                              </div>
                              <div id="collapse-faq-1" className="collapse " aria-labelledby="heading-faq-1" data-parent="#faq-accordion">
                                <div className="card-body">
                                  Hoodie tote bag mixtape tofu. Typewriter jean shorts wolf quinoa, messenger bag organic freegan cray.
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="heading-faq-2">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link btn-block text-left  collapsed "
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse-faq-2"
                                    aria-expanded="true"
                                    aria-controls="collapse-faq-2"
                                  >
                                    How Long Will It Take To Get My Package?
                                  </button>
                                </h2>
                              </div>
                              <div id="collapse-faq-2" className="collapse " aria-labelledby="heading-faq-2" data-parent="#faq-accordion">
                                <div className="card-body">
                                  Swag slow-carb quinoa VHS typewriter pork belly brunch, paleo single-origin coffee Wes Anderson. Flexitarian
                                  Pitchfork forage, literally paleo fap pour-over. Wes Anderson Pinterest YOLO fanny pack meggings, deep v XOXO
                                  chambray sustainable slow-carb raw denim church-key fap chillwave Etsy. +1 typewriter kitsch, American Apparel tofu
                                  Banksy Vice.
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="heading-faq-3">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link btn-block text-left  collapsed "
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse-faq-3"
                                    aria-expanded="true"
                                    aria-controls="collapse-faq-3"
                                  >
                                    What Payment Methods Are Accepted?
                                  </button>
                                </h2>
                              </div>
                              <div id="collapse-faq-3" className="collapse " aria-labelledby="heading-faq-3" data-parent="#faq-accordion">
                                <div className="card-body">
                                  Fashion axe DIY jean shorts, swag kale chips meh polaroid kogi butcher Wes Anderson chambray next level semiotics
                                  gentrify yr. Voluptate photo booth fugiat Vice. Austin sed Williamsburg, ea labore raw denim voluptate cred proident
                                  mixtape excepteur mustache. Twee chia photo booth readymade food truck, hoodie roof party swag keytar PBR DIY.
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="heading-faq-4">
                                <h2 className="mb-0">
                                  <button
                                    className="btn btn-link btn-block text-left  collapsed "
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapse-faq-4"
                                    aria-expanded="true"
                                    aria-controls="collapse-faq-4"
                                  >
                                    Is Buying On-Line Safe?
                                  </button>
                                </h2>
                              </div>
                              <div id="collapse-faq-4" className="collapse " aria-labelledby="heading-faq-4" data-parent="#faq-accordion">
                                <div className="card-body">
                                  Art party authentic freegan semiotics jean shorts chia cred. Neutra Austin roof party Brooklyn, synth Thundercats
                                  swag 8-bit photo booth. Plaid letterpress leggings craft beer meh ethical Pinterest.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="Reviews">
                          <div className="my-3">
                            <h6 className="mb-2">Images from customer (6)</h6>
                            <div className="block--review">
                              <div className="block__images row m-0 block__images_total">
                                <Link to="" className="col-lg-1 col-sm-2 col-3 more-review-images ">
                                  <div className="position-relative">
                                    <img
                                      src=""
                                      alt="Angie’s Boomchickapop Sweet &amp; Salty Kettle Corn"
                                      className="img-responsive rounded border h-100"
                                    />
                                  </div>
                                </Link>
                                <Link to="" className="col-lg-1 col-sm-2 col-3 more-review-images ">
                                  <div className="position-relative">
                                    <img
                                      src=""
                                      alt="Angie’s Boomchickapop Sweet &amp; Salty Kettle Corn"
                                      className="img-responsive rounded border h-100"
                                    />
                                  </div>
                                </Link>
                                <Link to="" className="col-lg-1 col-sm-2 col-3 more-review-images ">
                                  <div className="position-relative">
                                    <img
                                      src=""
                                      alt="Angie’s Boomchickapop Sweet &amp; Salty Kettle Corn"
                                      className="img-responsive rounded border h-100"
                                    />
                                  </div>
                                </Link>
                                <Link to="" className="col-lg-1 col-sm-2 col-3 more-review-images ">
                                  <div className="position-relative">
                                    <img
                                      src=""
                                      alt="Angie’s Boomchickapop Sweet &amp; Salty Kettle Corn"
                                      className="img-responsive rounded border h-100"
                                    />
                                  </div>
                                </Link>
                                <Link to="" className="col-lg-1 col-sm-2 col-3 more-review-images ">
                                  <div className="position-relative">
                                    <img
                                      src=""
                                      alt="Angie’s Boomchickapop Sweet &amp; Salty Kettle Corn"
                                      className="img-responsive rounded border h-100"
                                    />
                                  </div>
                                </Link>
                                <Link to="" className="col-lg-1 col-sm-2 col-3 more-review-images ">
                                  <div className="position-relative">
                                    <img
                                      src=""
                                      alt="Angie’s Boomchickapop Sweet &amp; Salty Kettle Corn"
                                      className="img-responsive rounded border h-100"
                                    />
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="comments-area">
                            <div className="row">
                              <div className="col-lg-8 block--product-reviews" id="product-reviews">
                                <h4 className="mb-30">Customer questions &amp; answers</h4>
                                <product-reviews-component url=""></product-reviews-component>
                              </div>
                              <div className="col-lg-4">
                                <h4 className="mb-30">Customer reviews</h4>
                                <div className="d-flex mb-30">
                                  <div className="product-rate d-inline-block mr-15">
                                    <div className="product-rating" style={{ width: "73.33%" }}></div>
                                  </div>
                                  <h6>3.67 out of 5</h6>
                                </div>
                                <div className="progress">
                                  <span>5 star</span>
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: "33.33%" }}
                                    aria-valuenow="33.33"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    33.33%
                                  </div>
                                </div>
                                <div className="progress">
                                  <span>4 star</span>
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: "33.33%" }}
                                    aria-valuenow="33.33"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    33.33%
                                  </div>
                                </div>
                                <div className="progress">
                                  <span>3 star</span>
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: "0%" }}
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    0%
                                  </div>
                                </div>
                                <div className="progress">
                                  <span>2 star</span>
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: "33.33%" }}
                                    aria-valuenow="33.33"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    33.33%
                                  </div>
                                </div>
                                <div className="progress">
                                  <span>1 star</span>
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: "0%" }}
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  >
                                    0%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="comment-form">
                            <h4 className="mb-15">Add a review</h4>
                            <div className="product-rate d-inline-block mb-30"></div>
                            <div className="row">
                              <div className="col-lg-8 col-md-12">
                                <form
                                  method="POST"
                                  action=""
                                  acceptCharset="UTF-8"
                                  className="form-contact comment_form form-review-product"
                                  encType="multipart/form-data"
                                >
                                  <p className="text-danger">
                                    Please <Link to="#">login</Link> to write review!
                                  </p>
                                  <input type="hidden" name="product_id" value="3" />
                                  <div className="form-group">
                                    <label>Quality</label>
                                    <div className="rate">
                                      <input type="radio" id="star1" name="star" value="1" />
                                      <label htmlFor="star1" title="text">
                                        1 star
                                      </label>
                                      <input type="radio" id="star2" name="star" value="2" />
                                      <label htmlFor="star2" title="text">
                                        2 star
                                      </label>
                                      <input type="radio" id="star3" name="star" value="3" />
                                      <label htmlFor="star3" title="text">
                                        3 star
                                      </label>
                                      <input type="radio" id="star4" name="star" value="4" />
                                      <label htmlFor="star4" title="text">
                                        4 star
                                      </label>
                                      <input type="radio" id="star5" name="star" value="5" /*checked*/ />
                                      <label htmlFor="star5" title="text">
                                        5 star
                                      </label>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <textarea
                                      className="form-control w-100"
                                      name="comment"
                                      id="comment"
                                      cols="30"
                                      rows="9"
                                      placeholder="Write Comment"
                                      disabled
                                    ></textarea>
                                  </div>
                                  <div className="form-group">
                                    <script data-cfasync="false" src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script>
                                    <script type="text/x-custom-template" id="review-image-template">
                                      <span className="image-viewer__item" data-id="__id__">
                                        <img
                                          src=""
                                          alt="Preview"
                                          className="img-responsive d-block"
                                        />
                                        <span className="image-viewer__icon-remove">
                                          <i className="fi-rs-cross"></i>
                                        </span>
                                      </span>
                                    </script>
                                    <div className="image-upload__viewer d-flex">
                                      <div className="image-viewer__list position-relative">
                                        <div className="image-upload__uploader-container">
                                          <div className="d-table">
                                            <div className="image-upload__uploader">
                                              <i className="fi-rs-camera image-upload__icon"></i>
                                              <div className="image-upload__text">Upload photos</div>
                                              <input
                                                type="file"
                                                name="images[]"
                                                data-max-files="6"
                                                className="image-upload__file-input"
                                                accept="image/png,image/jpeg,image/jpg"
                                                multiple="multiple"
                                                data-max-size="2048"
                                                data-max-size-message="The __attribute__ must not be greater than __max__ kilobytes."
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="loading">
                                          <div className="half-circle-spinner">
                                            <div className="circle circle-1"></div>
                                            <div className="circle circle-2"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <span className="help-block d-inline-block">
                                        You can upload up to 6 photos, each photo maximum size is 2048 kilobytes
                                      </span>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <button type="submit" className="button button-contactForm" disabled>
                                      Submit Review
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-60">
                <div className="col-12">
                  <h3 className="section-title style-1 mb-30">You may also like</h3>
                </div>
                <div>
                </div>
              </div>
              <div>
                <ProductBoxes productType="popular" />
              </div>
              <div className="row mt-60" id="related-products">
                <div className="col-12">
                  <h3 className="section-title style-1 mb-30">Related products</h3>
                </div>
              </div>
            </div>
            <div>
              <ProductBoxes productType="dealsday" />
            </div>
          </div>
        </main>
      </Layout>
    </div>
  );
};
export default ProductDetails;
